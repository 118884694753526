<template>
  <div class="course-navbar flex flex_acenter flex_jcbetween fontsize_14 color_222 bgc_fff">
    <div
      class="course-lr-box pointer flex flex_acenter"
      @click="backto"
      style="padding-right:11px;"
    >
      <!-- <img :src="backHomeSrc" alt="" style="width:14px;height:14px;"> -->
      <div class="nav-hover">
        <i class="icon-home-management fontsize_16"></i>
        <span class="padding_left6">{{$t('lang.managementpage')}}</span>
      </div>
    </div>
    <div class="pointer prel flex flex_acenter" @click="showRenameModal" style="z-index:1">
      <span
        class="fontsize_16 inlineblock course-title-html pover"
        :title="courseDetail['course-title']"
      >{{courseDetail['course-title']}}</span>
      <i class="course-rename-top icon-course-rename-top fontsize_16 padding_left8">
        <span class="path1"></span>
        <span class="path2"></span>
      </i>
      <div class="course-name-pop prel" :class="{'displaynone': !topCourseNameShow}" @click.stop>
        <div class="flex flex_acenter">
          <label for class="flexshrink textright" style="width:70px;">课程名：</label>
          <el-input
            placeholder="请输入课程名"
            class="margin_left5 noborder"
            :value="inputTemp"
            @input="inputCourseName"
            maxlength="10"
          ></el-input>
        </div>
        <el-button type="danger" class="course-name-sure" @click="renameCourse">确定</el-button>
        <div class="popper-arrow"></div>
      </div>
    </div>
    <div
      class="course-name-modal"
      :class="{'displaynone': !topCourseNameShow}"
      @click.stop="topCourseNameShow = false"
    ></div>
    <div class="course-lr-box flex flex_acenter flex_end">
      <div class="nav-hover pointer flex flex_acenter" @click="showGlobal">
        <i class="icon-global-setting fontsize_16">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
        <span class="padding_left6">{{$t('lang.globalsetting')}}</span>
      </div>
      <!-- v-if="is_formal" -->
      <div
        v-if="getVersion === '专业版'"
        @click.stop="downloadOpen"
        class="nav-hover pointer fontsize_18 margin_left8 publishBtn"
      >
        <i class="is-normal" v-if="getVersion === '免费版'"></i>
       
            <i v-if="downloading" class="el-icon-loading"></i>
          <i v-else class="icon-publish1 normal">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        <span class="padding_left6 fontsize_14">{{$t('lang.publish')}}</span>
       
      </div>
      <div class="course-share-button pointer margin_left16" @click="toShareCourse">
        <i class="icon-share"></i>
        <span class="padding_left6 fontsize_12">{{$t('lang.share')}}</span>
      </div>
      <div class="course-preview-button margin_left24 pointer" @click.stop="previewOpen">
        <i
          class
          :class="{'el-icon-loading':previewLoading.visible,'icon-preview':!previewLoading.visible}"
        ></i>
        <span
          class="padding_left6 fontsize_12"
        >{{!previewLoading.visible ? $t('lang.preview') : $t('lang.previewing')}}</span>
      </div>
    </div>
    <hint-dialog style="display: none;" :visible.sync="upDateVip" :appendToBody="true" :hint="hintObj" @click="ToupdateVip" :ismore="true"></hint-dialog>
 
    <fd-dialog
      :title="globalSetting_dialog.title"
      :visible.sync="globalSetting_dialog.dialogVisible"
      width="1036px"
      :before-close="handleClose"
      custom-class="global-dialog"
    >
      <div class="tabs_card">
        <div
          class="scroll-box width0 tabs_header_scorll"
          :style="{'padding-bottom':0,
              '--line-translateY': `${ 68 * Number(globalSetting_dialog.activeIndex) +1}px`,
              '--border-height': `${68 * Number(globalSetting_tabs.length) + 50}px` }"
        >
          <div class="tabs_header prel">
            <div
              v-for="(titem,tind) in globalSetting_tabs"
              :key="titem.id"
              @click="tapTab(tind,titem)"
              class="tabs_nav_item pointer"
              :class="{'active': titem == globalSetting_dialog.activeTab,'isFirst':tind == 0}"
            >{{globalSetting_list[titem].title}}</div>
          </div>
        </div>
        <div class="tabs_content">
          <globalsetForm
            :ruleForm="ruleForm[globalSetting_dialog.activeTab]"
            :propList="modalPropList"
            :fieldType="globalSetting_dialog.activeTab"
            :ckeditorNameId="'globalSetting'"
            @selectChange="formChangeBlur"
            @formItemBlur="formChangeBlur"
            @updateSort="updateSort"
            @addResource="showResource"
            @editResource="editResource"
            @delResource="delResource"
            @changeCheck="checkResource"
          ></globalsetForm>
        </div>
      </div>
      <fd-dialog
        :append-to-body="true"
        :title="resource_dialog.title"
        :visible.sync="resource_dialog.dialogVisible"
        :before-close="closeResourceDialog"
        width="1036px"
        custom-class="course-dialog full resource-dialog"
      >
        <div v-for="(ritem) in resource_dialog.propList" :key="ritem.name">
          <div v-if="ritem.display == 1" class="margin_bot24">
            <p class="fontsize_14 color_222 padding_bot8">{{ritem.title}}</p>
            <el-select
              v-if="ritem['input_type'] == 'single_select'"
              :value="resourceForm[ritem.name]"
              @change="selectChange($event,ritem.name)"
              placeholder="请选择"
              class="set-bgimg-select noborder"
            >
              <el-option
                v-for="item in ritem.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <div v-else-if="ritem['input_type'] == 'text'" class="global-sets-item">
              <el-input
                v-model="resourceForm[ritem.name]"
                :placeholder="ritem.placeholder"
                class="modal-sets-inlineinput noborder bgc_fff"
                @input="formItemBlur"
              ></el-input>
            </div>
            <div v-else-if="ritem['input_type'] === 'file'" class="pointer">
              <!-- <el-upload
                    v-show="fileList.length === 0 && !resourceForm[ritem.name]"
                    class="upload-demo"
                    ref="uploadFile"
                    name="file"
                    :headers="headers"
                    :action="'/api/V1/upload/file'"
                    :multiple="false"
                    :auto-upload="true"
                    :show-file-list="false"
                    :before-upload="uploadBefore"
                    :on-success="(response, file, fileList) => { return uploadSuccess({response, file, fileList},ritem.name); }"
                    :on-preview="uploadPreview"
                    :on-remove="uploadRemove"
                    :file-list="fileList">
                    <button size="small" type="primary" class="dialog-form-upload flex flex_acenter flex_center fontsize_12 color_222 pointer"><i class="icon-course-add"></i><span class="padding_left8">上传文件</span></button>
              </el-upload>-->
              <button
                v-show="fileList.length === 0 && !resourceForm[ritem.name]"
                @click="showUploadDialog(ritem.name)"
                size="small"
                type="primary"
                class="dialog-form-upload flex flex_acenter flex_center fontsize_12 color_222 pointer"
              >
                <i class="icon-course-add"></i>
                <span class="padding_left8">上传文件</span>
              </button>
              <div v-show="fileList.length !== 0 || resourceForm[ritem.name]">
                <div v-for="fitem in fileList" :key="fitem.id" class="upload-list padding_top8">
                  <span class="fontsize_14 color_4A90E2">{{fitem.original_name || fitem.name}}</span>
                  <i
                    class="icon-remove-picture margin_left16 fontsize_16 pointer remove-picture"
                    @click="clearUploadFile"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <input v-else-if="ritem.display == 0" type="hidden" :value="resourceForm[ritem.name]" />
        </div>
        <div class="flex flex_acenter flex_center">
          <button @click="saveResource" class="sureBtn pointer">
            <span class="fontsize_12">保存</span>
          </button>
          <button @click="closeResourceDialog" class="A1 pointer margin_left20">
            <span class="fontsize_12">关闭</span>
          </button>
        </div>
      </fd-dialog>
      <mediaSelectDialog
        uploadBtnMargin="0 0 0 18px"
        contentContainerPadding="16px 0 24px 0"
        :cardWidth="136"
        :appendToBody="true"
        :visible="mediaDialogSet.visible"
        :title="mediaDialogSet.title"
        :fromDetail="mediaDialogSet.item"
        :mediaType="mediaDialogSet.mediaType"
        @select="selectMedia"
        @close="closeMediaDialog"
      ></mediaSelectDialog>
    </fd-dialog>
    <fd-dialog
      :title="currentDialogSet.title"
      :visible.sync="currentDialogSet.visible"
      width="600px"
      :before-close="handleClose"
      :custom-class="{'course-dialog not-full':true, 'preview-error-dialog':dialogType === 'previewError'}"
    >
      <p
        v-if="dialogType === 'courseRename'"
        class="fontsize_14 color_222 margin_bot7"
      >{{currentDialogSet.label}}</p>
      <el-form v-if="dialogType === 'courseRename'" @submit.native.prevent>
        <el-form-item>
          <el-input
            :value="inputTemp"
            autocomplete="off"
            :placeholder="currentDialogSet.placeholder"
            @input="inputCourseName"
            class="h48 border_D1 noborder"
          ></el-input>
        </el-form-item>
      </el-form>
      <span v-if="dialogType === 'courseRename'" slot="footer" class="dialog-footer">
        <button type="primary" @click="renameCourse" class="course-save pointer">保存</button>
        <button @click="currentDialogSet.visible = false" class="course-close pointer">关闭</button>
      </span>
      <div v-if="dialogType === 'previewError'" class="course-preview-error">
        <p class="margin_bot16 fontsize_16 color_222 textcenter">{{currentDialogSet.p1}}</p>
        <p class="margin_bot16 fontsize_12 color_E74362 textcenter">{{currentDialogSet.p2}}</p>
        <el-scrollbar class="error-scrollview" :wrapStyle="[{'overflow-x':'hidden'}]">
          <div style="height:300px;" class="padding_0_16">
            <errorNavItem
              v-for="(item,ind) in currentDialogSet.items"
              :key="ind"
              :detail="item"
              @click="navtoError"
              class="margin_top16"
            ></errorNavItem>
          </div>
        </el-scrollbar>
        <div class="dialog-footer flex flex_center margin_top16">
          <button
            type="primary"
            @click="currentDialogSet.visible = false"
            class="course-save pointer"
          >确认</button>
          <button @click="currentDialogSet.visible = false" class="course-close pointer">关闭</button>
        </div>
      </div>
    </fd-dialog>
    <fd-upgrade-dialog :closeOnClickModal="false" :appendToBody="true" :show.sync="upgradeDialogVisible" @close="upgradeDialogVisible = false"></fd-upgrade-dialog>
    <!-- <fd-dialog
      fullscreen
      lock-scroll
      :show-close="false"
      :visible.sync="previewLoading.visible"
      custom-class="preview-loading-dialog">
      <div class="preview-loading-container">
        <img src="@/assets/fd-loading.png" class="loading-img" />
        <div class="flex flex_acenter fontsize_14 color_222">
          <p>加载中</p>
        </div>
        <div @click="cancelPreview" class="cancel-btn flex flex_acenter flex_center"><span class="fontsize_14 color_222">取消</span></div>
      </div>
    </fd-dialog>-->
  </div>
</template>

<script>
import backHome from "@/assets/svg/back-home.svg";
import { getToken } from "@/libs/utils/auth";
import { previewCourse, getCourseKey } from "@/libs/api/others";
import { mapState, mapActions, mapGetters } from "vuex";
import globalsetForm from "@/renderer/components/common/globalsetForm.vue";
import mediaSelectDialog from "@/renderer/components/common/mediaSelectDialog";
import errorNavItem from "@/renderer/components/common/errorNavItem.vue";
import {
  updateCompletionCondition,
  getHomeSetting,
  putHomeSetting,
  getGlobalSetting,
  getCompletionCondition,
  getButtonText,
  getPopupAnimation,
  getScreenSize,
  getBookmarkSettings,
  getAuxiliaryResources,
  updateButtonText,
  updatePopupAnimation,
  updateScreenSize,
  updateBookmarkSettings,
  updateAuxiliaryResources,
} from "@/libs/api/course.js";
export default {
  components: {
    globalsetForm,
    mediaSelectDialog,
    errorNavItem,
  },
  data() {
    return {
      backHomeSrc: backHome,
      token: null,
      // previewLoading:false, //是否预览中
      courseName: "未命名课程",
      inputTemp: "",
      topCourseNameShow: false,
      globalSetting_dialog: {
        title: "全局设置",
        dialogVisible: false,
        activeTab: 0,
        activeIndex: 0,
      },
      dialogType: "previewError", //courseRename 课程重命名、previewError 预览报错
      dialogDetail: {
        title: "命名课程",
        visible: false,
        label: "课程名称",
        placeholder: "请输入课程名称",
      },
      previewDialogSet: {
        //预览报错弹窗
        title: "预览错误",
        p1: "抱歉，课程存在以下原因无法预览",
        p2: "请修改以下内容后再进行预览",
        visible: false,
        items: {},
      },
      mediaDialogSet: {
        //选择媒体资源弹窗
        title: "媒体库",
        visible: false,
        item: {},
        mediaType: "all",
        propName: "",
      },
      ruleForm: {},
      globalSetting_tabs: [],
      globalSetting_list: {},
      resource_dialog: {
        title: "添加文件",
        dialogVisible: false,
        propList: [],
        form: {},
      },
      resourceForm: {},
      fileList: [],
      othersParams: {},
      popupWindow: null,
      previewLoading: {
        previewing: false, //预览中
        visible: false,
        pageFrame: null, //页面
      },
      downloading: false, //下载、发布中
      versioName:'', //版本信息
      upDateVip:false, // 升级弹框
      upgradeDialogVisible:false,
      hintObj:{
        content: `<strong>您当前为${'免费版'}，请升级版本后使用。</strong>`,
            version: `该功能为<span style="font-weight:bolder;">专业版,企业版</span>可用`
      },
    };
  },
  computed: {
    ...mapState({
      schemaJson: (state) => state.course.schemaJson,
      is_web: (state) => state.app.isWebSide,
    }),
    ...mapGetters([
      "getCourseDetailById",
      "getFirstPageById",
      "getVersionName",
    ]),
    courseInfo() {
      return this.getCourseDetailById(this.$route.params.id);
    },
    firstPageDetail() {
      return this.getFirstPageById(this.$route.params.id) || {};
    },
    courseDetail() {
      let courseSet = this.getCourseDetailById(this.$route.params.id);
      let detail = (courseSet && courseSet.course) || {};
      detail.course_id = this.$route.params.id;
      return detail;
    },
    getVersion() {
      //获取版本信息
      this.versioName = this.getVersionName().version.name;
      return this.getVersionName().version.name;
    },
    modalPropList() {
      // 当前tab属性列表
      return (
        (this.globalSetting_list[this.globalSetting_dialog.activeTab] &&
          this.globalSetting_list[this.globalSetting_dialog.activeTab]
            .detail) ||
        {}
      );
    },
    headers() {
      return {
        Authorization: "Bearer " + this.token,
      };
    },
    currentDialogSet() {
      // 用于课程重命名或者预览报错
      let dialogType = this.dialogType;

      // 课程重命名
      if (dialogType === "courseRename") {
        return this.dialogDetail;
      }
      // 预览报错
      if (dialogType === "previewError") {
        return this.previewDialogSet;
      }
    },
    titleDot() {
      let title = this.courseDetail["course-title"];
      return (
        (title && title.slice(0, 16) + (title.length > 16 ? "..." : "")) || ""
      );
    },
    is_formal() {
      //是否正式域名
      return process.env.VUE_APP_API.indexOf("e.fei-dao.com") == -1;
    },
  },
  watch: {
    $route: {
      handler(n) {
        // if(n && this.$route.path.indexOf('/course') > -1 || n && this.$route.path.indexOf('/theme') > -1) {
        //   this.globalSetting_dialog.dialogVisible = false;
        //   this.resource_dialog.dialogVisible = false;
        //   this.ruleForm = {};
        //   this.getGlobalSets();
        // }
      },
      immediate: true,
    },
  },
  async created() {
    const token = await getToken();
    this.token = token;
  },
  mounted() {
    this.inputTemp = this.courseDetail["course-title"];
    this.globalSetting_dialog.dialogVisible = false;
    this.resource_dialog.dialogVisible = false;
    this.ruleForm = {};
    // this.getGlobalSets();
  },
  methods: {
    ...mapActions([
      "SaveCourse",
      "RenameCourse",
      "GetCourseDetail",
      "GetHomeSetting",
      "GetTree",
    ]),
    cancelPreview() {
      // 取消预览
      this.previewLoading.pageFrame && this.previewLoading.pageFrame.close();
      this.previewLoading.previewing = false;
      this.previewLoading.visible = false;
    },
    navtoError({ detail }) {
      let timeNow = new Date().getTime(); //用于区分路由 - 报错时跳转

      this.previewDialogSet.visible = false;
      switch (detail.type) {
        case "article":
          this.$router.push({
            name: "EditContent",
            params: {
              type: "edit",
              id: detail.params.course_id,
              contentId: detail.params.contentobject_id, //（报错跳转-只需要一次params）刷新会消失，不会重复触发
              is_redirect: 1,
              article_id: detail.params.article_id,
            },
            query: {
              pageType: "content",
              is_redirect: timeNow,
            },
          });
          break;
        case "block":
          this.$router.push({
            name: "EditContent",
            params: {
              type: "edit",
              id: detail.params.course_id,
              contentId: detail.params.contentobject_id,
              is_redirect: 1,
              article_id: detail.params.article_id,
              block_id: detail.params.block_id,
            },
            query: {
              pageType: "content",
              is_redirect: timeNow,
            },
          });
          break;
        case "contentobject":
          this.$router.push({
            name: "EditContent",
            params: {
              type: "edit",
              id: detail.params.course_id,
              contentId: detail.params.contentobject_id,
              is_redirect: 1,
            },
            query: {
              pageType:
                detail.contentobject_type == "page" ? "content" : "menu",
              is_redirect: timeNow,
            },
          });
          break;
        case "component":
          this.$router.push({
            name: "EditContent",
            params: {
              type: "edit",
              id: detail.params.course_id,
              contentId: detail.params.contentobject_id,
              is_redirect: 1,
              article_id: detail.params.article_id,
              block_id: detail.params.block_id,
            },
            query: {
              pageType: "content",
              is_redirect: timeNow,
            },
          });
          break;
      }
    },
    downloadOpen() {
      if( this.versioName === '专业版'){
      // 下载
      if (this.downloading) {
        this.$message.info("请勿重复点击，即将导出...");
        return;
      }
      this.downloading = true;
      getCourseKey(this.$route.params.id)
        .then((res) => {
          this.downloading = false;
          if (res.errorcode == 0) {
            // 打开新窗口
            // this.refreshPopupWindow(res.data.preview_url, this.$route.params.id);
            // downloadCourse(res.data.download_key).then().catch();
            window.open(
              process.env.VUE_APP_API +
                "/api/V1/preview/downloadByKey?download_key=" +
                res.data.download_key
            );
          }
        })
        .catch(() => {
          this.downloading = false;
        });
      }else{
        //弹框升级
        this.upDateVip = true;
      }
    },
    ToupdateVip(){
      //去升级
      this.upDateVip = false;
      this.upgradeDialogVisible = true
    },
    refreshPopupWindow(url, pageId) {
      let popupWindow = this.popupWindow;

      if (popupWindow && !popupWindow.closed) {
        // popupWindow is open, refresh it
        popupWindow.close();
        setTimeout(() => {
          this.popupWindow = window.open(url, pageId);
        }, 10);
      } else {
        // Open a new popup window
        this.popupWindow = window.open(url, pageId);
      }
    },
    openPreviewWindow(url, course_id) {
      // 打开预览窗口

      this.previewLoading.pageFrame.close();
      let subFrame = window.open(url, "_blank");
      this.previewLoading.pageFrame = subFrame;
      // previewWindow.location.href = "/#/preview/"+course_id+"?url="+url, "preview";
      //  this.previewLoading.pageFrame.location.href = url;
    },
    previewOpen() {
      // 预览
      if (this.previewLoading.visible) {
        this.$message.info("请勿重复点击，即将预览...");
        return;
      }
      this.previewLoading.visible = true;

      if (this.previewLoading.pageFrame) this.previewLoading.pageFrame.close();
      if (this.is_web) {
        // 网页端
        this.previewLoading.pageFrame = window.open(
          "/#/preview/" + this.$route.params.id,
          "preview"
        );
      }

      previewCourse(this.$route.params.id)
        .then((res) => {
          if (res.errorcode == 0 && res.msg !== "打包失败") {
            // 打开新窗口
            if (this.is_web) {
              this.previewLoading.pageFrame.location.replace(
                res.data.preview_url
              );
            } else {
              // electron端
              let { href } = this.$router.resolve({
                path: "/preview/" + this.$route.params.id,
                query: {
                  url: res.data.preview_url,
                },
              });
              this.previewLoading.pageFrame = window.open(href, "_blank");
            }

            this.previewLoading.visible = false;
          } else {
            // 预览出错
            this.previewLoading.visible = false;
            this.previewLoading.pageFrame &&
              this.previewLoading.pageFrame.close();
            this.previewError(res);
          }
        })
        .catch(() => {
          this.previewLoading.pageFrame &&
            this.previewLoading.pageFrame.close();
          this.previewLoading = false;
        });
    },
    previewError(detail) {
      // 预览出错 - 弹窗显示
      this.dialogType = "previewError";
      this.previewDialogSet.items = Array.isArray(detail.data.errors)
        ? detail.data.errors
        : [detail.data.errors];
      this.previewDialogSet.visible = true;
    },
    showUploadDialog(propName) {
      //显示媒体资源弹窗
      this.mediaDialogSet.visible = true;
      this.mediaDialogSet.propName = propName;
    },
    closeMediaDialog() {
      // 关闭弹窗/后
      this.mediaDialogSet.visible = false;
      this.mediaDialogSet.item = {};
    },
    selectMedia({ item, fromDetail }) {
      this.fileList.push(item);
      this.$set(this.resourceForm, this.mediaDialogSet.propName, item.url);
      this.closeMediaDialog();
      //修改封面
      // updateCourseCover({course_id: fromDetail.course_id, cover: item.url}).then(res => {
      //   if(res.errorcode == 0) {
      //     this.closeMediaDialog();
      //     // 重新加载课程列表
      //     this.getList();
      //   }
      // })
    },
    checkResource({ item, prop, index, value }) {
      // 下载 勾选改变
      let { _resourcesItems } = this.ruleForm["auxiliaryResources"];
      _resourcesItems[index][prop] = value;

      this.updateSingleSets("auxiliaryResources", {
        value: _resourcesItems,
        prop: "_resourcesItems",
      });
    },
    editResource(item, ind, propList) {
      // 编辑资源

      propList.forEach((v) => {
        this.resourceForm[v.name] = v.default != undefined ? v.default : "";
      });
      Object.assign(this.resourceForm, item);

      if (this.resourceForm._link) {
        //文件路径
        let name = this.resourceForm._link.substring(
          this.resourceForm._link.lastIndexOf("/") + 1
        );
        this.fileList = [{ name: name }];
      }
      this.resource_dialog.title = "编辑文件";
      this.resource_dialog.form = this.ruleForm["auxiliaryResources"];
      this.resource_dialog.propList = propList;
      this.resource_dialog.dialogVisible = true;
    },
    delResource(item, ind) {
      // 删除资源

      let { _resourcesItems } = this.ruleForm["auxiliaryResources"];
      _resourcesItems.splice(ind, 1);

      this.updateSingleSets("auxiliaryResources", {
        value: _resourcesItems,
        prop: "_resourcesItems",
      });
    },
    saveResource() {
      // 添加资源
      let propList = this.resource_dialog.propList;
      let targetInd = propList.findIndex((v) => {
        return (
          v.required == 1 &&
          (this.resourceForm[v.name] === "" ||
            this.resourceForm[v.name] === undefined)
        );
      });
      if (targetInd > -1) {
        this.$message.warning(propList[targetInd].title + "必填");
        return;
      }
      this.resource_dialog.form._resourcesItems.push(this.resourceForm);

      let params = {
        course_id: this.courseDetail.course_id,
        _resourcesItems: this.resource_dialog.form._resourcesItems,
      };
      let currentForm = this.ruleForm[this.globalSetting_dialog.activeTab];
      Object.assign(params, currentForm);

      updateAuxiliaryResources(params).then((res) => {
        if (res.errorcode == 0) {
          // 刷新数据
          this.resource_dialog.dialogVisible = false;
          this.resource_dialog.form = {};
          this.resourceForm = {};
          this.getSingleSets("auxiliaryResources");
        }
      });
    },
    closeResourceDialog(done) {
      // 关闭添加资源弹窗
      if (done && typeof done == "function") {
        done();
      }
      this.resource_dialog.dialogVisible = false;
      this.resource_dialog.form = {};
      this.resourceForm = {};
      this.fileList = [];
    },
    uploadBefore() {},
    uploadSuccess({ response, file, fileList }, prop) {
      if (response.errorcode == 0) {
        this.fileList = [];
        this.fileList.push(response.data);
        this.$set(this.resourceForm, prop, response.data.path);
      } else {
        this.$message.warning(response.msg);
      }
    },
    uploadPreview() {},
    uploadRemove() {},
    clearUploadFile() {
      // 清空已上传的文件列表
      // if(Array.isArray(this.$refs.uploadFile)) {
      //   this.$refs.uploadFile[0].clearFiles();
      // } else {
      //   this.$refs.uploadFile.clearFiles();
      // }
      this.fileList = [];
      this.resourceForm._link = "";
    },
    selectChange(e, prop) {
      this.$set(this.resourceForm, prop, e);
      this.$forceUpdate(); //组件嵌套太深，强制刷新
    },
    formItemBlur(e, prop) {
      this.$forceUpdate(); //组件嵌套太深，强制刷新
    },
    showResource(propList, form) {
      // 显示添加资源弹窗
      propList.forEach((v) => {
        this.$set(
          this.resourceForm,
          v.name,
          v.default != undefined ? v.default : ""
        );
      });
      this.fileList = [];
      this.resource_dialog.title = "添加文件";
      this.resource_dialog.form = form;
      this.resource_dialog.propList = propList;
      this.resource_dialog.dialogVisible = true;
    },
    getGlobalSets(type = "") {
      // 获取全局配置
      getGlobalSetting().then((res) => {
        if (res.errorcode == 0) {
          this.globalSetting_dialog.title = res.data.globalSetting_title;
          this.globalSetting_list = res.data.tab_list;
          this.globalSetting_tabs = res.data.tab_sort.filter(
            (v) => res.data.tab_list[v].display == true
          );
          if (!type)
            this.globalSetting_dialog.activeTab = this.globalSetting_tabs[0];
          this.globalSetting_tabs.forEach((v, ind) => {
            if (!this.ruleForm[v]) {
              this.ruleForm[v] = {};
              this.$forceUpdate();
              if (
                this.$route.path.indexOf("/course") > -1 ||
                this.$route.path.indexOf("/theme") > -1
              ) {
                //当前页
                this.getSingleSets(v);
              }
            }
          });
        }
      });
    },
    getSingleSets(type) {
      // 获取各项配置-详情

      let course_id = this.courseDetail.course_id || this.$route.params.id;
      if (!course_id || course_id == undefined) return;
      switch (type) {
        case "completionCondition":
          // 完成条件
          getCompletionCondition(course_id).then((res) => {
            if (res.errorcode == 0) {
              this.ruleForm[type] = res.data;
            }
          });
          break;
        case "homeSetting":
          // 首页设置
          getHomeSetting(course_id).then((res) => {
            if (res.errorcode == 0) {
              this.$set(this.ruleForm, type, res.data);
              this.$forceUpdate(); //强制刷新
            }
          });
          break;
        case "auxiliaryResources":
          // 辅助资源
          getAuxiliaryResources(course_id).then((res) => {
            if (res.errorcode == 0) {
              this.$set(this.ruleForm, type, res.data);
              this.$forceUpdate(); //强制刷新
            }
          });
          break;
        case "buttonText":
          // 按钮文字
          getButtonText(course_id).then((res) => {
            if (res.errorcode == 0) {
              this.$set(this.ruleForm, type, res.data);
            }
          });
          break;
        case "screenSize":
          // 屏幕尺寸
          getScreenSize(course_id).then((res) => {
            if (res.errorcode == 0) {
              this.$set(this.ruleForm, type, res.data);
            }
          });
          break;
        case "popupAnimation":
          // 弹出动画
          getPopupAnimation(course_id).then((res) => {
            if (res.errorcode == 0) {
              this.$set(this.ruleForm, type, res.data);
            }
          });
          break;
        case "bookmarkSettings":
          // 书签设置
          getBookmarkSettings(course_id).then((res) => {
            if (res.errorcode == 0) {
              this.$set(this.ruleForm, type, res.data);
            }
          });
          break;
      }
    },
    updateSingleSets(type, { value, prop }) {
      // 更新各项设置
      let course_id = this.courseDetail.course_id;
      let params = {
        course_id: course_id,
        [prop]: value,
      };
      let currentForm = this.ruleForm[this.globalSetting_dialog.activeTab];
      Object.assign(params, currentForm); //更新单项里各个值
      switch (type) {
        case "completionCondition":
          updateCompletionCondition(params).then((res) => {
            if (res.errorcode == 0) {
              // 刷新课程数据 ？
            } else {
              // this.$message.error(res.msg);
            }
          });
          break;
        case "homeSetting":
          putHomeSetting(params).then((res) => {
            if (res.errorcode == 0) {
              // 刷新课程数据 ？
            } else {
              // this.$message.error(res.msg);
            }
          });
          break;
        case "buttonText":
          updateButtonText(params).then((res) => {
            if (res.errorcode == 0) {
              // 刷新课程数据 ？
            } else {
              // this.$message.error(res.msg);
            }
          });
          break;
        case "popupAnimation":
          updatePopupAnimation(params).then((res) => {
            if (res.errorcode == 0) {
              // 刷新课程数据 ？
            } else {
              // this.$message.error(res.msg);
            }
          });
          break;
        case "screenSize":
          updateScreenSize(params).then((res) => {
            if (res.errorcode == 0) {
              // 刷新课程数据 ？
            } else {
              // this.$message.error(res.msg);
            }
          });
          break;
        case "bookmarkSettings":
          updateBookmarkSettings(params).then((res) => {
            if (res.errorcode == 0) {
              // 刷新课程数据 ？
            } else {
              // this.$message.error(res.msg);
            }
          });
          break;
        case "auxiliaryResources":
          updateAuxiliaryResources(params).then((res) => {
            if (res.errorcode == 0) {
              // 刷新课程数据 ？
              this.getSingleSets(type);
              if (prop === "_isEnabled") {
                // 更新课程数据
                this.GetCourseDetail({
                  course_id: this.courseDetail.course_id,
                  refreshOther: false,
                }).then((res) => {
                  if (res.errorcode == 0) {
                    this.ruleForm = Object.assign(
                      this.ruleForm,
                      this.courseInfo.course
                    );
                  }
                });
              }
            } else {
              // this.$message.error(res.msg);
            }
          });
          break;
      }
    },
    showTypeModal(type) {
      //显示弹窗，并且 type限定tab
      this.globalSetting_dialog.dialogVisible = true;
      this.getGlobalSets(type);

      let targetIndex = -1;
      switch (type) {
        case "firstpage":
          this.globalSetting_dialog.activeTab = "homeSetting";
          this.getSingleSets("homeSetting");
          break;
      }
    },
    formChangeBlur({ value, prop, ruleForm, fieldType }) {
      // console.log( 'formChangeBlur' ,value, prop, fieldType);
      this.ruleForm = this.changeFormProp(prop, value, fieldType);
      this.updateSingleSets(fieldType, { value, prop });
    },
    changeFormProp(prop, data, fieldType) {
      //更新courseForm 某个属性
      let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
      ruleForm[fieldType][prop] = data;
      return ruleForm;
    },
    backto() {
      this.$router.push("/home");
      // this.$navBlank({
      //   path: '/home',
      //   pageId: "courseList",
      //   isBack:true
      // });
    },
    updateSort(list) {
      // console.log("updateSort",list);
      putHomeSetting({
        course_id: this.courseDetail.course_id,
        _startIds: list,
        _force: this.ruleForm["course-_start-properties-_force"] || false,
        _isMenuDisabled:
          this.ruleForm["course-_start-properties-_isMenuDisabled"] || false,
      }).then((res) => {
        if (res.errorcode == 0) {
          this.GetCourseDetail({ course_id: this.courseDetail.course_id }).then(
            (res) => {
              if (res.errorcode == 0) {
                this.ruleForm = Object.assign(
                  this.ruleForm,
                  this.courseInfo.course
                );
              }
            }
          );
          this.GetHomeSetting(this.courseDetail.course_id);
        }
      });
    },
    tapTab(ind, item) {
      this.globalSetting_dialog.activeIndex = ind;
      this.globalSetting_dialog.activeTab = item;

      this.getSingleSets(item);
    },
    showGlobal() {
      //显示全局设置弹窗
      this.globalSetting_dialog.activeTab = "";
      this.globalSetting_dialog.dialogVisible = true;
      this.getGlobalSets();
    },
    handleClose(done, type) {
      // console.log('handleclose',type)
      done();
    },
    showRenameModal() {
      this.dialogType = "courseRename";
      this.dialogDetail.visible = true;
      this.inputTemp = this.courseDetail["course-title"];
    },
    renameCourse() {
      //重命名按钮
      this.dialogDetail.visible = false;

      this.RenameCourse({
        course_id: this.courseDetail.course_id,
        title: this.inputTemp,
      })
        .then((res) => {
          if (res.errorcode == 0) {
            this.GetCourseDetail({
              course_id: this.courseDetail.course_id,
              refreshOther: false,
            });
            this.GetTree(this.courseDetail.course_id);
          }
        })
        .catch();
    },
    inputCourseName(val) {
      // 课程名称输入
      this.inputTemp = val;
    },
    toShareCourse() {
      this.$emit("toShare");
    },
  },
};
</script>

<style  lang="less" scoped>
.publishBtn{
  border-radius: 16px !important;
  padding: 0 10px !important;
  height: 32px !important;
  line-height: 32px !important;
  box-sizing: border-box;
  width: auto;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  color: #73461d;
  background-image: linear-gradient(90deg, #f3d9ad 0%, #ebc17b 100%);
  position: relative;
  margin-right: 8px;
  &:hover{
    background-image: linear-gradient(90deg, #F4DEB8 0%, #EFCE96 100%);
  }&:focus,&:focus-visible,&:active{
    background-image: linear-gradient(90deg, #E3CBA3 0%, #DDB779 100%);
  }
  & .is-normal {
    position: absolute;
    top: -7px;
    right: -8px;
    font-size: 12px;
    color: #fad49c;
    background: #161211;
    border-radius: 4px;
    padding: 0 3px;
    font-style: normal;
    width: 25px;
    height: 16px;
    &::before {
      content: "VIP";
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translate(-50%, -10%);
    }
  }
  .icon-publish1.normal::before {
    content: "";
    background: url("~@/assets/svg/user-vip.svg");
    background-size: 12px;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    display: inline-block;
  }
}
.course-navbar {
  position: relative;
  // z-index: 99; //导致弹窗异常
  border: 1px solid #dfe5eb;
  padding: 0 24px 0 13px;
  height: 48px;
}
.course-preview-button {
  border-radius: 16px;
  width: 64px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  background-color: #e74362;
  &:hover {
    background-color: #b5102f;
  }
}
.course-share-button {
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid #e74362;
  width: 64px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #e74362;
  &:hover {
    color: #b5102f;
    border-color: #b5102f;

    .icon-share::before {
      color: #b5102f;
    }
  }
}
.course-name-pop {
  position: absolute;
  margin-top: 13px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  padding: 16px 0;
  width: 342px;
  // height: 117px;
  height: 105px;
  background: #ffffff;
  .el-input {
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    width: 253px;
    height: 34px;
    line-height: 34px;
    background: #ffffff;
  }
}
.course-name-sure {
  margin-top: 8px;
  margin-left: 75px;
  border-radius: 4px;
  padding: 0;
  width: 60px;
  height: 32px;
  color: #ffffff;
  letter-spacing: 0.82px;
  background: #e74362;
}
.popper-arrow {
  top: -24px;
}

.course-name-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-color: rgba(0,0,0,0.12);
}
.course-title-html {
  overflow: hidden;
  // max-width: 130px;
  height: 22px;
  line-height: 22px;
  max-width: 17em; //16个中文字符+省略号
  & /deep/p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// tabs
.tabs_card {
  display: flex;
  width: 100%;
  height: 100%;
  .tabs_header_scorll {
    position: relative;
    background-color: #fafafa;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      right: 0px;
      height: 500px;
      height: var(--border-height);
      width: 1px;
      background-color: #d4d4d4;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      top: var(--line-translateY);
      right: -1px;
      height: 68px;
      width: 1px;
      background-color: #fff;
    }
  }
  .tabs_header {
    flex-shrink: 0;
    width: 100px;
    height: 100%;
    .active-right-line {
      position: absolute;
      top: 0;
      right: -1px;
      height: 66px;
      width: 20px;
      background-color: #fff;
      z-index: 3;
    }
  }
  .tabs_content {
    flex: 1;
    background-color: #ffffff;
  }
  .tabs_nav_item {
    box-sizing: border-box;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    width: 100%;
    height: 68px;
    line-height: 68px;
    text-align: center;
    font-size: 14px;
    color: #a1a1a1;
    &.active {
      border-top-color: #d4d4d4;
      border-bottom-color: #d4d4d4;
      color: #222222;
      background-color: #ffffff;
      &.isFirst {
        border-top-color: transparent;
      }
    }
  }

  // button
  button.global-save {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 18px;
    border: none;
    width: 78px;
    height: 36px;
    font-size: 14px;
    color: #ffffff;
    background-color: #e74362;
  }
}

// 上传按钮
button.dialog-form-upload {
  box-sizing: border-box;
  border: 1px solid #d4d4d4;
  border-radius: 18px;
  width: 106px;
  height: 36px;
  line-height: 34px;
  background: #fafafa;
}

/deep/.el-upload-list {
  display: none;
}
.upload-list {
  height: 24px;
  line-height: 24px;
  .remove-picture {
    vertical-align: middle;
    padding: 3px;
  }
}
.course-lr-box {
  min-width: 330px;
}
// 预览错误
.course-preview-error {
  // 滚动区域
  .error-scrollview {
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    height: 210px;
    background: #ffffff;
    // 重置滚动条
    /deep/.el-scrollbar__bar.is-vertical {
      right: 1px;
      width: 4px !important;
    }
    /deep/.el-scrollbar__thumb {
      border-radius: 3px;
      height: 64px !important;
      background-color: #a1a1a1;
    }
  }
}
// 预览加载
.preview-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.loading-img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}
.cancel-btn {
  border: 1px solid #d4d4d4;
  border-radius: 16px;
  margin-top: 40px;
  width: 78px;
  height: 32px;
  transition: background 0.6s;
  cursor: pointer;
  &:hover {
    background-color: #ededed;
  }
}
// 加载中三个点
.snippet {
  position: relative;
  background: #fff;
  width: 50px;
  // padding: 2rem 5%;
  // margin: 1.5rem 0;
  // box-shadow: 0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px #f0f2f7;
  border-radius: 0.25rem;
}
.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  // margin: 0 -5%;
  overflow: hidden;
}
@dotColor: #000;
.dot-typing {
  position: relative;
  left: -99px;
  // left: -9999px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: @dotColor;
  color: @dotColor;
  // box-shadow: 9984px 0 0 0 @dotColor, 9999px 0 0 0 @dotColor, 10014px 0 0 0 @dotColor;
  box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor, 104px 0 0 0 @dotColor;
  // -webkit-animation: dot-typing 1.5s infinite linear;
  // animation: dot-typing 1.5s infinite linear;
  -webkit-animation: dot-typing 1s infinite linear;
  animation: dot-typing 1s infinite linear;
}

@-webkit-keyframes dot-typing {
  0% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  16.667% {
    box-shadow: 84px -10px 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  33.333% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  50% {
    box-shadow: 84px 0 0 0 @dotColor, 94px -10px 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  66.667% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  83.333% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px -10px 0 0 @dotColor;
  }
  100% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
}

@keyframes dot-typing {
  0% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  16.667% {
    box-shadow: 84px -10px 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  33.333% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  50% {
    box-shadow: 84px 0 0 0 @dotColor, 94px -10px 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  66.667% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
  83.333% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px -10px 0 0 @dotColor;
  }
  100% {
    box-shadow: 84px 0 0 0 @dotColor, 94px 0 0 0 @dotColor,
      104px 0 0 0 @dotColor;
  }
}

@media screen and (max-width: 1336px) {
  .course-title-html {
    max-width: 9em; //8个中文字符+省略号
  }
}

.nav-hover {
  border-radius: 13px;
  padding: 0 8px;
  height: 26px;
  line-height: 26px;
  &:hover {
    background-color: #f5f5f5;
  }
}
</style>

