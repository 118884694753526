<template>
  <div class="coursepanel-sidebar flex prel">
    <div  class="sidebar-tabs prel fontsize_14 color_59">
      <div class="sidebar-tabs-item flex flexcolumn flex_center flex_acenter pointer" :class="{'active': activeTab === 'courseEdit', 'notactive': activeTab === 'none'}" @click.stop="clickTab('courseEdit')">
        <i :class="[ activeTab === 'courseEdit' ? 'icon-page-active' : 'icon-page-default', 'icon-page']" ></i>
        <span class="margin_top4">页面</span>
        <!-- <div class="sidebar-tabs-svg type-page" :class="{'active': activeTab === 'courseEdit'}"></div> -->
        <!-- <span class="sidebar-tabs-tips">页面</span> -->
      </div>
      <div class="sidebar-tabs-item flex flexcolumn flex_center flex_acenter pointer" :class="{'active': activeTab === 'courseTheme' }" @click.stop="clickTab('courseTheme')">
        <i :class="[ activeTab === 'courseTheme' ? 'icon-theme-active' : 'icon-theme-default', 'icon-theme']" >
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
        </i>
        <span class="margin_top4">主题</span>
        <!-- <div class="sidebar-tabs-svg type-theme" :class="{'active': activeTab === 'courseTheme'}"></div> -->
        <!-- <span class="sidebar-tabs-tips">主题</span> -->
      </div>
      <!-- <div class="sidebar-tabs-item flex flexcolumn flex_center flex_acenter pointer" :class="{'active': activeTab === 'courseSetting', 'notactive': activeTab === 'none'}" @click.stop="clickTab('courseSetting')">
        <div class="sidebar-tabs-svg type-setting" :class="{'active': activeTab === 'courseSetting'}"></div>
        <span class="sidebar-tabs-tips">设置</span>
      </div> -->
      <!-- <div class="toUpgrade" @click="upgradeDialogVisible = true"></div> -->
    </div>
    <div
      class="sidebar-content prel"
      :style="{ width: isExpand ? '22rem' : '0px' }" >
      <div v-if="activeTab === 'courseEdit'" class="height_100p bgc_fff">
        <div class="sidebar-fold hidden height_100p prel " :class="{'shadow': !drawerSet.visible }">
          <div class="pageTree-wrapper height_100p">
            <div class="pageTree">
              <el-input placeholder="请输入搜索内容" v-model="searchPage" class="sidebar-search noborder">
                <i
                  slot="prefix"
                  class="input__icon icon-search color_999 fontsize_14"
                ></i>
              </el-input>
              <div class="pageTree-inner">
                <fd-tree
                  @node-click="tapTreeNode"
                  class="filter-tree"
                  empty-text="暂无搜索结果"
                  :expand-on-click-node="false"
                  :highlight-current="showTools"
                  :editId="ruleForm['contentobject-id']"
                  node-key="id"
                  :data="courseTrees"
                  :props="defaultProps"
                  default-expand-all
                  :filter-node-method="filterNode"
                  :indent="20"
                  draggable
                  icon-class="el-icon-folder"
                  ref="tree"
                  @node-drag-start="handleDragStart"
                  @node-drag-enter="handleDragEnter"
                  @node-drag-leave="handleDragLeave"
                  @node-drag-over="handleDragOver"
                  @node-drag-end="handleDragEnd"
                  @node-drop="handleDrop"
                  :allow-drop="allowDrop"
                  :allow-drag="allowDrag"
                >
                  <!-- :style="'padding-left:'+ 16*node.level +'px;'" -->
                  <span class="custom-tree-node" slot-scope="{ node, data }" 
                    :class="[currentRoute(data),editHighlight(data)]">
                    <div class="custom-tree-node-inner">
                      <i class="icon-tree-folder4 fontsize_16" v-if="data._type === 'menu' || data._type == undefined" style="display:none;"></i>
                      <i class="icon-tree-content fontsize_16" v-else ></i>
                      <!-- 'width:'+ ( (146-node.level*16)+(data._type != 'page' ? 8 : 0) )+'px;' -->
                      <div class="fontsize_14 color_26 pover custom-tree-label-html"
                        :class="{'isFold': data._type === 'page', 'padding_left10':data._type === 'page', 'padding_left0':data._type != 'page' }"
                        :style="{
                          '--tree-label-width':  (data._type != 'page' ? 50 : 64) +'px'
                        }"
                        v-html="node.label" :title="node.label"></div>
                      <span class="custom-tree-dropdown">
                        <el-dropdown @command="treeNodeTool">
                          <span class="el-dropdown-link padding_10" @click.stop="">
                            <i class="icon-gray-more fontsize_20 icon_color_BFBFBF"></i>
                          </span>
                          <el-dropdown-menu
                            slot="dropdown"
                            @mouseenter.native="toolshover(1, data.id)"
                            @mouseleave.native="toolshover(2, data.id)"
                          >
                            <el-dropdown-item
                              v-if="(data._type === 'menu' || data._type == undefined) && node.level < 6"
                              :command="commandItem('addcontentpage', data)"
                              >{{$t('lang.addcontentpage')}}</el-dropdown-item
                            >
                            <el-dropdown-item
                              v-if="(data._type === 'menu' || data._type == undefined) && node.level < 5"
                              :command="commandItem('addthemepage', data)"
                              >{{$t('lang.addthemepage')}}</el-dropdown-item
                            >
                            <el-dropdown-item
                              :divided="(data._type === 'menu' || data._type == undefined)"
                              :command="commandItem('styles', data)"
                              >{{$t('lang.setstyle')}}</el-dropdown-item
                            >
                            <el-dropdown-item
                              v-if="!data.is_course"
                              :command="commandItem('bookmarker', data)"
                              >{{$t('lang.editbookmarks')}}</el-dropdown-item
                            >
                            <el-dropdown-item
                              v-if="!data.is_course"
                              :command="commandItem('firstpage', data)"
                              >{{$t('lang.setashomepage')}}</el-dropdown-item
                            >
                            <el-dropdown-item
                              :divided="!(data._type === 'menu' || data._type == undefined)"
                              :command="commandItem('condition', data)"
                              >{{$t('lang.completeconditions')}}</el-dropdown-item
                            >
                            <el-dropdown-item
                              v-if="!data.is_course"
                              :divided="!!(data._type === 'menu' || data._type == undefined)"
                              :command="commandItem('rename', data)"
                              >{{$t('lang.rename')}}</el-dropdown-item
                            >
                            <el-dropdown-item
                              v-if="!data.is_course"
                              :divided="false"
                              :command="commandItem('copy', data)"
                              >{{$t('lang.copying')}}</el-dropdown-item
                            >
                            <el-dropdown-item
                              v-if="!data.is_course"
                              :divided="!(data._type === 'menu' || data._type == undefined)"
                              :command="commandItem('del', data)"
                              ><span class="color_E74362">{{$t('lang.delete')}}</span></el-dropdown-item
                            >
                          </el-dropdown-menu>
                        </el-dropdown>
                      </span>
                    </div>
                  </span>
                </fd-tree>
              </div>
            </div>
          </div>
        </div>
        <div class="left-modal" v-if="!drawerSet.clickTime" :class="{ visible: drawerSet.visible }">
          <div
              class="
                left-modal-header
                modal-header
                flex flex_acenter flex_jcbetween
                padding_16
                lineheight_22
              "
            >
              <div class="fontsize_16 color_26 flex">
                <span class="flexshrink">{{drawerSet.title}}（</span>
                <span class="poverOfHtmlTile richHtml_c222 pover" style="max-width:120px;" v-html="drawerSet.htmlTitle"></span>
                <span class="flexshrink">）</span>
              </div>
              <i
                class="icon-close-popup fontsize_14 icon_color_59 pointer"
                @click.stop="hideLeftModal"
              ></i>
            </div>
          <div v-if="drawerSet.visible" class="left-modal-main">
            <leftForm
              v-if="drawerSet.type !== 'firstpage'"
              :ruleForm="ruleForm" :tabList="tabList"
              :ckeditorNameId="getCkId()"
              @handleClick="handleClick"
              @selectChange="formChangeBlur" 
              @formItemBlur="formChangeBlur"
              :class="{'margin_top24': tabList.length > 1}"
            ></leftForm>
            <div v-else class="padding_left16">
              <div v-for="(hitem) in homepageSet" :key="ruleForm.contentobject_id + '_' + hitem.id" class="margin_top24">
                <div v-if="hitem.display == 1 && hitem.input_type == 'truefalse' && hitem.properties_name === '_skipIfComplete' && ruleForm['contentobject-__start-properties-status'] == 1">
                  <p class="fontsize_14 color_222 margin_bot8">{{hitem.title}}</p>
                  <p v-if="hitem.help" class="fontsize_12 color_8C margin_bot10">{{hitem.help}}</p>
                  <el-switch
                    @change="changeHomeSet($event,hitem)"
                    v-model="ruleForm[hitem.key_name]"
                    :width="52"
                    active-color="#E74362"
                    inactive-color="#D4D4D4"
                    class="">
                  </el-switch>
                </div>
                <div v-else-if="hitem.display == 1 && hitem.input_type == 'truefalse' && hitem.properties_name !== '_skipIfComplete'">
                  <p class="fontsize_14 color_222 margin_bot8">{{hitem.title}}</p>
                  <p v-if="hitem.help" class="fontsize_12 color_8C margin_bot10">{{hitem.help}}</p>
                  <el-switch
                    @change="changeHomeSet($event,hitem)"
                    v-model="ruleForm[hitem.key_name]"
                    :width="52"
                    active-color="#E74362"
                    inactive-color="#D4D4D4"
                    class="">
                  </el-switch>
                </div>
              </div>
              <div class="">
                <p class="fontsize_14 color_222 margin_bot8">
                  <span>调整首页顺序</span>
                </p>
                <p class="margin_top4 fontsize_12 color_8C margin_bot10">当存在其他页面也被设为首页时，通过首页顺序进行页面排序</p>
                <customButton  @click="toSetIndexOrder" plain :roundPX="15" :width="52" :height="30" color="#E74362">
                  <span class="fontsize_12">编辑</span>
                </customButton>
              </div>
            </div>
          </div>
        </div>
        <div class="left-modal-mask" :class="{'left-mask-hide': drawerSet.modalHide }" @click.stop="hideLeftModal"></div>
      </div>
      <div v-else-if="activeTab === 'courseTheme'" class="sidebar-theme-container height_100p bgc_fff">
        <div class="hidden sidebar-theme-tabs-outer">
          <div class="sidebar-theme-tabs" >
            <div @click="changeThemeActive(0)" class="theme-tab-item fontsize_14 pointer" :class="{'color_8C': themeActive != 0, 'color_26 active': themeActive == 0}"><span>当前主题</span></div>
            <div @click="changeThemeActive(1)" class="theme-tab-item margin_left5 fontsize_14 pointer" :class="{'color_8C': themeActive != 1, 'color_26 active': themeActive == 1}"><span>更多主题</span></div>
          </div>
        </div>
        <div class="sidebar-theme-list">
          <scrollView  @reachBottom="loadThemeData">
            <div v-if="themeActive === 1" class="flex flex_end width_100p my_template_outer" @click="showMyTemplate">
              <span class="fontsize_14 my_template_text">我的模板</span>
            </div>
            <div v-for="(item,index) in themeList.list" 
            :key="item.id" class="theme-item" 
            :class="{ 'editing': isEditItem(item) }">
              <div class="theme-item-inner" :class="{'active': item.is_current == true}">
                <div class="theme-checkbox-wrapper prel flex flex_acenter">
                  <div class="theme-checkbox-mask" @click="sureSelectDialog(index,themeActive === 0, 'theme',item)"></div>
                  <!-- <checkBox :label="item.name" :value="item.is_current == true" @change="selectTheme({isselect:$event, index})"></checkBox> -->
                  <p class="fontsize_14 color_26">{{item.name}}</p>
                  <el-dropdown
                    :class="{'dropdown-show':themeActive == 2}"
                    @click.stop=""
                    @command="handleTools"
                    @visible-change="toolsVisibleChange"
                    class="theme-item-edit"
                    :show-timeout="500"
                    :hide-timeout="200"
                    placement="bottom"
                  >
                    <span @click.stop="" class="el-dropdown-link theme-mask-edit pointer">
                      <i class="icon-gray-more fontsize_18"></i>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      :append-to-body="true"
                    >
                      <el-dropdown-item :command="commandVal('copying', item, index, themeActive == 0 ? 'courseTheme' :'theme')">
                        <span style="width:75px;display:inline-block;">{{
                        $t("lang.copying")
                      }}</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="commandVal('delete', item, index, themeActive == 0 ? 'courseTheme' :'theme')"><span class="color_FF4D4F">{{
                        $t("lang.delete")
                      }}</span></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="theme-item-image prel">
                  <el-image :src="item.cover" fit="fill" class="img"></el-image>
                  <div v-if="themeActive == 0" @click="tapAction(item,'edit', themeActive == 0 ? 'courseTheme' : 'theme')" class="theme-item-mask flex flex_center flex_acenter pointer">
                    <div class="icon-wrapper flex flexcolumn flex_acenter">
                      <i class="icon-edit-thin fontsize_16 color_fff edit_icon"></i>
                      <span class="margin_top4 fontsize_12 color_fff edit_text">{{$t('lang.edit')}}</span>
                    </div>
                    <!-- <div v-if="themeActive == 1" class="split_line"></div>
                    <div v-if="themeActive == 1" @click="tapAction(item,'copy', themeActive == 0 ? 'theme' : 'template')" class="icon-wrapper flex flexcolumn flex_acenter">
                      <i class="icon-copy-thin fontsize_16 color_fff edit_icon"></i>
                      <span class="margin_top4 fontsize_12 color_fff edit_text">{{$t('lang.copying')}}</span>
                    </div>
                    <div v-if="themeActive == 1" class="split_line"></div>
                    <div v-if="themeActive == 1" @click="tapAction(item,'delete', themeActive == 0 ? 'theme' : 'template')" class="icon-wrapper flex flexcolumn flex_acenter">
                      <i class="icon-del-thin fontsize_16 color_fff edit_icon"></i>
                      <span class="margin_top4 fontsize_12 color_fff edit_text">{{$t('lang.delete')}}</span>
                    </div> -->
                  </div>
                  <div  v-else @click="sureSelectDialog(index, false, 'theme', item)" class="theme-item-mask flex flex_center flex_acenter pointer">
                    <div class="flex flexcolumn flex_acenter icon-btn">
                      <span class="fontsize_14 color_fff">{{$t('lang.use')}}</span>
                    </div>
                  </div>
                </div>
                <div v-if="item.is_vip == 1" class="tag_vip fontsize_12">
                  <img src="~@/assets/svg/member_vip.svg" alt="" class="tag_vip_icon">
                  <span v-for="(vips, vind) in item.theme_vip" :key="vips.id">{{vips.name}}{{vind !== item.theme_vip.length - 1 ? '/' : ''}}</span>
                </div>
              </div>
            </div>
          </scrollView>
        </div>
        <div class="left-modal template_modal sidebar-template-list" v-if="!drawerTemplate.clickTime" :class="{ visible: drawerTemplate.visible }">
          <div class="fontsize_14 color_26 padding_left16">我的模板</div>
          <scrollView  @reachBottom="loadTemplateData">
            <div v-for="(item,index) in templateData.list" 
            :key="item.id" class="theme-item" 
            :class="{ 'editing': isEditItem(item, true) }">
              <div class="theme-item-inner" :class="{'active': item.is_current == true}">
                <div class="theme-checkbox-wrapper prel flex flex_acenter">
                  <div class="theme-checkbox-mask" @click="sureSelectDialog(index,item.is_current, 'template',item)"></div>
                  <!-- <checkBox :label="item.name" :value="item.is_current == true" @change="selectTheme({isselect:$event,index})"></checkBox> -->
                  <p class="fontsize_14 color_26">{{item.name}}</p>
                  <el-dropdown
                    :class="{'dropdown-show':true}"
                    @click.stop=""
                    @command="handleTools"
                    @visible-change="toolsVisibleChange"
                    class="theme-item-edit"
                    :show-timeout="500"
                    :hide-timeout="200"
                    placement="bottom"
                  >
                    <span @click.stop="" class="el-dropdown-link theme-mask-edit pointer">
                      <i class="icon-gray-more fontsize_18"></i>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      :append-to-body="true"
                    >
                      <el-dropdown-item :command="commandVal('edit', item, index, 'template')" divided>
                        <span style="width:75px;display:inline-block;">{{
                        $t("lang.edit")
                      }}</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="commandVal('copying', item, index, 'template')" divided>
                        <span style="width:75px;display:inline-block;">{{
                        $t("lang.copying")
                      }}</span>
                      </el-dropdown-item>
                      <el-dropdown-item :command="commandVal('delete', item, index, 'template')" divided><span class="color_FF4D4F">{{
                        $t("lang.delete")
                      }}</span></el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="theme-item-image prel">
                  <el-image :src="item.cover" fit="fill" class="img"></el-image>
                  <!-- <div @click="tapAction(item,'edit', 'template')" class="theme-item-mask flex flex_center flex_acenter pointer">
                    <div class="icon-wrapper flex flexcolumn flex_acenter">
                      <i class="icon-edit-thin fontsize_20 color_fff edit_icon"></i>
                      <span class="margin_top4 fontsize_12 color_fff edit_text">{{$t('lang.edit')}}</span>
                    </div>
                  </div> -->
                  <div @click="sureSelectDialog(index,false, 'template',item)" class="theme-item-mask flex flex_center flex_acenter pointer">
                    <div class="icon-btn flex flexcolumn flex_acenter">
                      <span class="fontsize_14 color_fff edit_text">{{$t('lang.use')}}</span>
                    </div>
                  </div>
                </div>
                <div v-if="item.is_vip == 1" class="tag_vip fontsize_12">
                  <img src="~@/assets/svg/member_vip.svg" alt="" class="tag_vip_icon">
                  <span v-for="(vips, vind) in item.theme_vip" :key="vips.id">{{vips.name}}{{vind !== item.theme_vip.length - 1 ? '/' : ''}}</span>
                </div>
              </div>
            </div>
          </scrollView>
        </div>
        <div class="left-modal-mask" :class="{'left-mask-hide': drawerTemplate.modalHide }" @click.stop="hideTemplateModal"></div>
      </div>
    </div>
    <div class="pickup_wrap">
      <div class="pickup_btn" @click="tapPickUp">
        <span :class="{'el-icon-arrow-right':!isExpand, 'el-icon-arrow-left':isExpand}" class="color_222 fontsize_18"></span>
        <!-- <img :src="!isExpand ? sidebarExpandSrc : sidebarPickupSrc" alt="" class="img"> -->
      </div>
    </div>
    <fd-dialog
      append-to-body
      :show-close="false"
      :visible.sync="currentDialogDetail.visible"
      width="600px"
      :before-close="(arg)=>beforeCloseDialog(arg, currentDialogDetail.type)"
      custom-class="course-dialog theme-dialog">
      <div class="flex">
        <div><i class="icon-upgrade-remind fontsize_24"></i></div>
        <div class="flex flexcolumn margin_left16">
          <p class="fontsize_16 color_26 margin_bot12 changeTheme-title">{{currentDialogDetail.p1}}</p>
          <p class="fontsize_14 lineheight_22 changeTheme-content" style="width:406px">{{currentDialogDetail.p2}}</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer flex flex_end">
        <el-button v-for="(citem, cind) in currentDialogDetail.btns" :key="citem.type"
          @click="tapBtn(citem.type, currentDialogDetail.item)"
          :type="cind==0 ? 'default' : citem.type == 'copyAndReplace' ? 'primary' : 'lightgreen'"
          class="common"
          >{{citem.title}}</el-button>
        <!-- <button type="primary" @click="replaceTheme(currentDialogDetail.item)" class="course-save pointer">更换</button>
        <button type="primary" @click="copyAndReplace(currentDialogDetail.item)" class="course-copy pointer">复制课程并更换</button>
        <button @click="currentDialogDetail.visible = false" class="course-close pointer">不换了</button> -->
      </span>
    </fd-dialog>
    <fd-dialog
      append-to-body
      :title="delDialogDetail.title"
      :visible.sync="delDialogDetail.visible"
      width="600px"
      :before-close="handleCloseDel"
      custom-class="course-dialog not-full rename-dialog">
      <div v-if="delDialogDetail.type === 'del'">
        <p class="fontsize_16 color_222 textcenter margin_bot16" style="padding-top:9px;">请确认是否删除<span v-if="delDialogDetail.item._type">{{delDialogDetail.item._type==='menu'?'主题页':'内容页'}}</span></p>
        <p class="fontsize_14 color_E74362 textcenter" style="padding-bottom:9px;">您确认要删除吗？删除之后将无法找回</p>
      </div>
      <span v-if="delDialogDetail.type === 'del'" slot="footer" class="dialog-footer">
        <button type="primary" @click="delPage" class="course-save pointer">确定删除</button>
        <button @click="closeDelDialog" class="course-close pointer">不删了</button>
      </span>
      <div v-if="delDialogDetail.type === 'rename'">
        <p class="fontsize_14 color_222 margin_bot7">页面名称</p>
        <ckeditor 
          type="simple" 
          class="fontsize_14 color_222"
          :id="'001'"
          :canKeyEnter="canKeyEnter"
          :value="delDialogDetail.inputVal" 
          :throttle="throttleDelay"
          @input="handleInput"
          @blur="handleBlur"
        >
        </ckeditor>
      </div>
      <span v-if="delDialogDetail.type === 'rename'" slot="footer" class="dialog-footer">
        <button type="primary" @click="savePageName" class="course-save pointer">保存</button>
        <button @click="closeDelDialog" class="course-close pointer">取消</button>
      </span>
    </fd-dialog>
    <fd-upgrade-dialog :closeOnClickModal="false" :appendToBody="true" :show.sync="upgradeDialogVisible" @close="handleUpgradeClose"></fd-upgrade-dialog>
    <hint-dialog :visible.sync="tipVisible" :appendToBody="true" :hint="hintObj" @click="toUpgrade" :ismore="true"></hint-dialog>
  </div>
</template>

<script>
import sidebarExpandSvg  from "@/assets/svg/sidebar_expand.svg";
import sidebarPickupSvg  from "@/assets/svg/sidebar_pickup.svg";
import editGlobalsettingDefault  from "@/assets/svg/leftsidebar/edit-globalsetting-default.svg";
import editGlobalsettingSelected  from "@/assets/svg/leftsidebar/edit-globalsetting-selected.svg";
import editPageDefault  from "@/assets/svg/leftsidebar/edit-page-default.svg";
import editPageSelected  from "@/assets/svg/leftsidebar/edit-page-selected.svg";
import editThemeDefault  from "@/assets/svg/leftsidebar/edit-theme-default.svg";
import editThemeSelected  from "@/assets/svg/leftsidebar/edit-theme-selected.svg";

import Config from '@/libs/config';
import { getContentObjectOne, updateIndexSet } from '@/libs/api/content';
import { getCourseCurrentTheme, getThemeList, getTemplateList, switchTheme, copyTemplate, delTemplate, copyCourseAndSwitchTheme } from '@/libs/api/theme';
import checkBox from "@/renderer/components/common/checkBox.vue";
import leftForm from "@/renderer/components/common/leftForm.vue";
import scrollView from "@/renderer/components/common/scrollView.vue";
import customButton from "@/renderer/components/common/button.vue";
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: { 
    checkBox, 
    leftForm, 
    scrollView, 
    customButton: customButton
  },
  data() {
    return {
      sidebarExpandSrc:sidebarExpandSvg,
      sidebarPickupSrc:sidebarPickupSvg,
      sidebarItemsSvgs: {
        globalsettingDefault: editGlobalsettingDefault,
        globalsettingSelected: editGlobalsettingSelected,
        pageDefault: editPageDefault,
        pageSelected: editPageSelected,
        themeDefault: editThemeDefault,
        themeSelected: editThemeSelected,
      },
      canKeyEnter:Config.titleCkeditorCanKeyEnter, //标题能否回车换行
      throttleDelay:Config.realTimeInput,
      searchPage: "",
      isExpand: true,
      activeTab: "courseEdit",
      themeActive:0, //0当前主题、1主题
      currentTheme: {
        page:1,
        limit:4,
        total:-2, //初始化-2 加载中
        backCheckIndex:-1, //被选中主题 - 备份
        checkIndex: -1, //被选中主题 - 当前应用的主题
        editIndex: -1, //当前编辑中的 主题
        list:[],
      },
      themeData: {
        page:1,
        limit:4,
        total:-2, //初始化-2 加载中
        backCheckIndex:-1, //被选中主题 - 备份
        checkIndex: -1, //被选中主题 - 当前应用的主题
        editIndex: -1, //当前编辑中的 主题
        list:[],
      },
      templateData:{
        page:1,
        limit:4,
        total:-2, //初始化-2 加载中
        checkIndex: -1, //被选中模板 - 当前应用的模板
        editIndex: -1, //当前编辑中的 模板
        list:[],
      },
      dialogDetailPropName:"themeDialogDetail", //用哪个弹窗数据
      themeDialogDetail: {
        type:"theme",
        title:"保存",
        p1:"请确认是否更换当前主题",
        p2:"更换主题可能修改您当前所设置的颜色、字体、图片等样式，且无法恢复。建议复制当前课程后再更换。",
        btns:[
          {
            title:"取消",
            type:"cancel",
            bgc:"#D4D4D4",
          },
          {
            title:"更换",
            type:"replaceTheme",
            bgc:"#E74362",
          },
          {
            title:"复制课程并更换",
            type:"copyAndReplace",
            bgc:"#D4D4D4",
          }
        ],
        visible:false,
        item:{},
        index:-1,
      },
      templateDialogDetail: {
        type:"template",
        title:"删除模板",
        p1:"请确认是否删除当前主题",
        p2:"删除后，已使用该模板的课程会置换到其对应的原始主题，请确认是否删除",
        btns:[
          {
            title:"确定删除",
            type:"deleteTemplate",
            bgc:"#E74362",
          },
          {
            title:"不删了",
            type:"cancel",
            bgc:"#D4D4D4",
          }
        ],
        visible:false,
        item:{},
        index:-1,
      },
      defaultProps: {
        children: "children",
        label: "title",
      },
      drawerSet: {
        direction: "ltr", //从左往右
        visible: false, //是否显示
        clickTime:false, //点击时不显示弹出窗口
        modalHide: true, //是否显示 遮罩
        modalTimer: null,
        duration: 0.5, //s
        title: "设置样式",
        activeName: 0,
        type: 'style'
      },
      drawerTemplate: {
        direction: "ltr", //从左往右
        visible: false, //是否显示
        clickTime:false, //点击时不显示弹出窗口
        modalHide: true, //是否显示 遮罩
        modalTimer: null,
        duration: 0.5, //s
        title: "我的模板",
        activeName: 0,
        type: 'style'
      },
      showTools: false,
      currentId: 0,
      ruleForm: {},
      popDetail:{},
      homepageSet:[
        {
          id:10241,
          title: "设为首页",
          key_name: "contentobject-__start-properties-status",
          help:"如果启用，本页面将被设为首页",
          input_type: "truefalse",
          properties_name: "status",
          display: "1"
        },
        {
          id:10242,
          title: "完成后跳过本页",
          key_name: "contentobject-__start-properties-_skipIfComplete",
          help:"如果启用，本页内容完成后，下次进入课程时可以跳过本页",
          input_type: "truefalse",
          properties_name: "_skipIfComplete",
          display: "1"
        },
        {
          id:10243,
          title: "类名",
          key_name: "contentobject-__start-properties-_className",
          input_type: "text",
          properties_name: "_className",
          display: "0"
        }
      ],
      lastHistory:null,
      delDialogDetail: {
        title:"删除",
        visible: false,
        item:{},
        type:"",
        inputVal:""
      },
      upgradeDialogVisible: false,
      hintObj:{},
      tipVisible:false,
    };
  },
  computed: {
    ...mapState({
      schemaJson: state => state.course.schemaJson,
      userVersion: (state) => state.user.userInfo.version.vid,
    }),
    ...mapGetters([
      'getTreesById'
    ]),
    currentDialogDetail() {
      // 当前弹窗数据
      return this[this.dialogDetailPropName]
    },
    courseTrees() {
      return this.getTreesById(this.$route.params.id)
    },
    routeType() {
      return this.$route.params && this.$route.params.type || 'edit'
    },
    pageType() { //区分首页 index、主题页 menu、内容页 content
      return this.$route.query.pageType;
    },
    tabList() {
      return this.popDetail && this.popDetail.tabgroup_list || [];
    },
    themeList() {
      return this.themeActive == 0 ? this.currentTheme : this.themeData
    },
  },
  watch: {
    '$route': {
      handler(n) {
        if(n && n.params.type === "template") {
          this.themeActive = 1;
          
          this.themeList.total < 0 && this.getThemeList();
          return;
        }
        if(n && n.params.type === "theme") { //更多主题
          this.themeActive = 1;
          
          this.themeList.total < 0 && this.getThemeList();
          return;
        }
        if(n && n.params.type === "course_theme") { //当前主题
          this.themeActive = 0;
          console.log('当前主题', this.themeList, this.currentTheme)
          
          this.themeList.total < 0 && this.getCurrentTheme();
          return;
        }
      },
      immediate: true,
      deep:true
    },
    searchPage(val) {
      this.$refs.tree && this.$refs.tree.filter(val)
    },
    'drawerSet.visible': {
      handler(n) {
        // console.log('drawerSet.visible',n);
        if(!n) {
          this.ruleForm = {};
        }
      },
      immediate:true,
      deep: true
    },
  },
  created() {

    // 初始化默认tab类型
    let tabsType = {
      'theme': 'courseTheme',
      'course_theme': 'courseTheme',
      'template': 'courseTheme',
      'edit': 'courseEdit',
      'create': 'courseCreate',
    };
    this.activeTab =  tabsType[this.$route.params.type];
    // 由于课程详情里获取了，所以不重复请求
    // this.GetTree(this.$route.params.id);
  },
  methods: {
    ...mapMutations(['SET_CUSTOM_CSS']),
    ...mapActions(['CreateContentObject','DelContentObjectOne','GetSchema',
      'SaveCourse','GetContentObjectOne','GetTree','MoveNode',
      'GetContentObjectSonList','GetHomeSetting', 'CopyContentObjectOne']),
    // 去升级
    toUpgrade() {
      this.tipVisible = false;
      this.upgradeDialogVisible = true;
    },
    showMyTemplate() {
      // 显示模板列表
      this.templateData.total < 0 && this.getTemplateList();
      this.drawerTemplate.visible = true;
      this.drawerTemplate.modalHide = false;
    },
    handleUpgradeClose() {},
    tapPickUp() {
      if(this.isExpand) {
        this.toggleSidebar('pickup');
      }else {
        // 如果没有选中页面或者主题，则弹框展开时，默认是页面
        if(this.activeTab === "none") this.activeTab = "courseEdit";
        this.toggleSidebar('expand');
      }
    },
    handleTools({ type, item, index , themeType}) { //themeType : courseTheme, theme, template
      
      if(type === "edit") {
        this.tapAction(item,'edit', themeType);
        return;
      }
      if(type === "copying") {
        this.tapAction(item,'copy', themeType);
        return;
      }
      if(type === "delete") {
        this.tapAction(item,'delete', themeType);
        return;
      }
    },
    toolsVisibleChange() {},
    commandVal(type, item, index, themeType) {
      return {
        type: type,
        item: item,
        index: index,
        themeType
      };
    },
    handleInput(val) {
      // 重命名输入
      // console.log('handleInput', val);
      this.delDialogDetail.inputVal = val;
    },
    handleBlur(val) {
      // 重命名失焦
      // console.log('handleBlur', val);
    },
    savePageName() {
      // 保存页面名称
      let { course_id, contentobject_id, _parentId } = this.delDialogDetail.item;
      let obj = {};
      let leftForm = {
        "course_id": course_id,
        "contentobject-id": contentobject_id,
        contentobject_id,
        refreshOther:false,
      };
      obj['contentobject-title'] = this.delDialogDetail.inputVal;
      
      this.popDetail.page_key = "contentobject-style-right";

      let params = {
        "page_key":this.popDetail.page_key,
        "course_id":leftForm.course_id,
        "properties_data": obj,
        "isLeftUpdate":true,
        "refreshOther": leftForm.refreshOther
      };
      if(this.popDetail.page_key && this.popDetail.page_key.indexOf('contentobject') > -1) {
        params["contentobject_id"] = leftForm['contentobject-id'];
      }
      //保存详情
      this.SaveCourse(params).then(res=>{
        if(res.errorcode == 0) {
          
          this.$message.success("重命名成功！");
          this.delDialogDetail.visible = false;

          if(this.popDetail.page_key.indexOf('course-style') > -1) {
            // 修改了左侧课程 - 样式设置
            this.ruleForm = Object.assign(this.ruleForm, res.data)
          }

          // 当前修改页面名称，如果是当前主题的子页面时, 刷新子列表
          let isMenu = this.pageType === 'menu' || this.pageType === 'index';
          let page_parentId = this.pageType === 'index' ? this.$route.params.id : this.pageType === 'menu' ? this.$route.params.contentId : "";
          if( isMenu && _parentId === page_parentId ) {
            this.getSonContentList(course_id, _parentId);
          }
          
        }
      })
    },
    updateListOfExtra(type) {
      // 外部更新列表使用
      if(type === "template") {
        this.templateData.page = 1;
        this.getTemplateList();
      }
    },
    loadThemeData() {
      // 加载主题、模板列表下一页
      let {page, total, limit} = this.themeList;
      if(page * limit >= total) return;
      this.themeList.page++;
      this.themeActive == 0 ? this.getCurrentTheme() : this.getThemeList();
    },
    loadTemplateData() {
      // 加载模板列表下一页
      let {page, total, limit} = this.templateData;
      if(page * limit >= total) return;
      this.templateData.page++;
      this.getTemplateList();
    },
    isEditItem(item, isTemplate = false) {
      //是否当前编辑项
      return !isTemplate && this.themeActive == 0 && this.$route.params.type === 'course_theme' && item.theme_key == this.$route.params.contentId 
      || !isTemplate && this.themeActive == 1 && this.$route.params.type === 'theme' && item.theme_key == this.$route.params.contentId
      || isTemplate && this.$route.params.type === 'template' && item.id == this.$route.params.contentId;
    },
    tapBtn(actionType, item) {
      // console.log('tapBtn',actionType)
      switch(actionType) {
        case "replaceTheme":
          // 更换主题
          this.replaceTheme(item);
          break;
        case "copyAndReplace":
          // 复制课程并更换
          this.copyAndReplace(item);
          break;
        case "deleteTemplate":
          // 删除模板
          this.tapDelTemplate(item);
          break;
        case "cancel":
          // 不换了
          this.currentDialogDetail.visible = false;
          break;
      }
    },
    tapAction(item, actionType, type) {//type : theme \ template
      if(!actionType) return;
      if(type === 'courseTheme' && actionType == 'edit' && this.$route.path.indexOf('/course/course_theme/') > -1 && this.$route.params.contentId == item.theme_key ) return;
      if(type === 'theme' && actionType == 'edit' && this.$route.path.indexOf('/course/theme/') > -1 && this.$route.params.contentId == item.theme_key ) return;
      if(type === 'template' && actionType == 'edit' && this.$route.path.indexOf('/course/template/') > -1 && this.$route.params.contentId == item.id ) return;

      // 记录原先页面
      this.lastHistory = {
        url: this.$route.fullPath
      };
      switch(actionType+'_'+type) {
        case "edit_theme":
          this.$router.push(`/course/theme/${this.$route.params.id}/${item.theme_key}`);
          break;
        case "edit_courseTheme":
          this.$router.push(`/course/course_theme/${this.$route.params.id}/${item.theme_key}`);
          break;
        case "edit_template":
          this.$router.push(`/course/template/${this.$route.params.id}/${item.id}`);
          break;
        case "copy_template":
          // 模板-复制
          this.tapCopyTemplate(item);
          break;
        case "delete_template":
          // 模板-删除
          this.dialogDetailPropName = "templateDialogDetail";
          this.currentDialogDetail.item = item;
          // this.currentDialogDetail.index = index;
          this.currentDialogDetail.visible = true;
          break;
      }
    },
    replaceTheme(item) {
      console.log( "replaceTheme",  "更换主题", item)
      // 更换主题
      this.selectTheme({isselect:true, index:this.themeDialogDetail.index, themeType: item.type === "theme_template" ? 'template': 'theme'},
        (resp)=>{
          this.changeListStatus(this.themeDialogDetail.index);
          
          this.themeDialogDetail.visible = false;
          this.themeDialogDetail.index = -1;
          this.themeDialogDetail.item = {};
          // 跳转对应编辑页面
          // if(resp.data.info.theme_key == this.$route.params.contentId) return;
          this.$router.push(`/course/course_theme/${this.$route.params.id}/${resp.data.info.theme_key}?time=`+new Date().getTime());
        });
    },
    copyAndReplace(item) {
      // 复制课程并更换主题
      this.copyTheme(true, this.themeDialogDetail.index,
        (resp)=>{
          this.themeDialogDetail.visible = false;
          this.themeDialogDetail.index = -1;
          this.themeDialogDetail.item = {};
          // 跳转复制页面
          this.$router.push(`/course/edit/${resp.data.course_id}?pageType=index`);
        });
    },
    sureSelectDialog(index,is_current, type = 'theme', item) {
      console.log('sureSelectDialog',index, is_current, type);
      if(is_current == true) return;
      console.log( 'item', item , this.userVersion)
      let { is_vip, theme_vip } = item;
      if(is_vip == 1 && this.userVersion) {
        let canUse = theme_vip.findIndex(v => v.version_id == this.userVersion);
        if(canUse === -1) {
          // 不可用，提示升级
          let versionStr = theme_vip.reduce(
            (previousValue, currentValue, currentIndex ) => previousValue  + currentValue.name + ( (currentIndex !== theme_vip.length - 1) ? '、' : '' ),
            ''
          );
          this.hintObj = {
            content: `<strong>您当前为${'免费版'}，请升级版本后使用。</strong>`,
            version: `该主题为<span style="font-weight:bolder;">${versionStr}</span>可用`
          }
          this.tipVisible = true;
          return;
        }
      }

      this.themeDialogDetail.item = type === 'theme' ? this.themeList.list[index] : this.templateData.list[index];
      this.themeDialogDetail.index = index;
      this.dialogDetailPropName = "themeDialogDetail";
      this.themeDialogDetail.visible = true;
    },
    copyTheme(isselect, index, callback) {
      // 复制课程 - 更换主题
      this.themeList.backCheckIndex = this.themeList.checkIndex; //备份
      this.themeList.checkIndex = isselect ? index : -1;

      if(isselect) {
        this.themeList.list[index].is_current = true;
      }
      if(this.themeList.backCheckIndex >= 0 && this.themeList.backCheckIndex < this.themeList.list.length) { //旧选中的取消选中
        this.themeList.list[this.themeList.backCheckIndex].is_current = false;
      }

      let item = this.themeList.list[index];
      let params = {
        type:item.type,
        course_id: this.$route.params.id,
        theme_key: item.theme_key
      };
      if(this.themeActive == 1) {
        params['theme_template_id'] = item.id;
      }
      copyCourseAndSwitchTheme(params).then(res => {
        if(res.errorcode == 0) {
          callback && callback(res);
        }
      }).catch();
    },
    selectTheme({isselect, index, themeType = 'theme'}, callback) {
      // 选中改变 - 更换主题
      let dataObj = themeType === 'theme' ? this.themeList : this.templateData;
      dataObj.backCheckIndex = dataObj.checkIndex; //备份
      dataObj.checkIndex = isselect ? index : -1;

      if(isselect) {
        dataObj.list[index].is_current = true;
      }
      if(dataObj.backCheckIndex >= 0 && dataObj.backCheckIndex < dataObj.list.length) { //旧选中的取消选中
        dataObj.list[dataObj.backCheckIndex].is_current = false;
      }

      let item = dataObj.list[index];
      let params = {
        type:item.type,
        course_id: this.$route.params.id,
        theme_key: item.theme_key
      };
      if(this.themeActive == 1) {
        params['theme_template_id'] = item.id;
      }
      switchTheme(params).then(res => {
        if(res.errorcode == 0) {
          callback && callback(res);
        }
      }).catch();
    },
    changeThemeActive(index) {
      // 切换当前主题、更多主题
      if(this.themeActive == index) return;
      this.themeActive = index;
      if(index == 0) {
        this.hideTemplateModal();
      }
      if(index == 0) {
        this.getCurrentTheme();

      }
      if(index == 1) {
        this.getThemeList();
      }
    },
    tapCopyTemplate(item) {
      // 复制模板
      copyTemplate({id:item.id}).then(res => {
        if(res.errorcode == 0) {
          this.templateData.page = 1;
          this.$message.success(res.msg);
          this.getTemplateList();
        }
      }).catch();
    },
    tapDelTemplate(item) {
      // 删除模板
      delTemplate({id:item.id, course_id:this.$route.params.id }).then(res => {
        if(res.errorcode == 0) {
          this.templateData.page = 1;
          this.currentDialogDetail.visible = false;
          this.currentDialogDetail.index = -1;
          this.$message.success(res.msg);
          this.getTemplateList();
        }
      }).catch();
    },
    changeListStatus(selected_index) {
      // 修改选中状态
      this.themeActive == 0 ? this.themeData : this.templateData
      if(this.themeActive == 0) {
        // 当前
        this.themeData.list.forEach((v,i) => {
          this.themeData.list[i].is_current = (i == selected_index);
        });
        // 非当前
        this.templateData.list.forEach((v,i) => {
          this.templateData.list[i].is_current = false;
        });
        return;
      }
      // 当前
      this.templateData.list.forEach((v,i) => {
        this.templateData.list[i].is_current = (i == selected_index);
      });
      // 非当前
      this.themeData.list.forEach((v,i) => {
        this.themeData.list[i].is_current = false;
      });
    },
    getCurrentTheme() {
      // 获取当前主题详情
      getCourseCurrentTheme({
        course_id: this.$route.params.id,
      }).then(res => {
        if(res.errorcode == 0) {
          this.currentTheme.total = 1;
          this.currentTheme.list = [res.data.info];
          console.log('获取到数据', this.currentTheme)
          this.currentTheme.checkIndex = 0;
          this.currentTheme.editIndex = res.data.info.theme_key == this.$route.params.contentId ? 0 : -1;
        }
      }).catch();
    },
    getThemeList() {
      //获取主题列表
      getThemeList({
        course_id: this.$route.params.id,
        page: this.themeData.page,
        limit: this.themeData.limit
      }).then(res => {
        if(res.errorcode == 0) {
          this.themeData.total = res.data.total;
          this.themeData.list = this.themeData.page > 1 ? this.themeData.list.concat(res.data.data) : res.data.data;
          // this.themeData.checkIndex = this.themeData.list.findIndex(v => v.is_current == true);
          this.themeData.editIndex = this.themeData.list.findIndex(v => v.theme_key == this.$route.params.contentId);
        }
      }).catch();
    },
    getTemplateList() {
      // 获取模板列表
      getTemplateList({
        course_id: this.$route.params.id,
        page: this.templateData.page,
        limit: this.templateData.limit
      }).then(res => {
        if(res.errorcode == 0) {
          this.templateData.total = res.data.total;
          this.templateData.list = this.templateData.page > 1 ? this.templateData.list.concat(res.data.data) : res.data.data;
        }
      }).catch();
    },
    beforeCloseDialog(done, type) {
      done();
    },
    toSetIndexOrder() {
      // 显示首页设置弹窗
      this.$emit("showGlobalModal","firstpage");
    },
    hideLeftModal() {
      // 隐藏左侧弹窗
      this.drawerSet.visible = false;
      this.drawerSet.modalTimer = setTimeout(()=>{
        this.drawerSet.modalHide = true;
      }, this.drawerSet.duration*1000);
    },
    hideTemplateModal() {
      // 隐藏左侧弹窗
      this.drawerTemplate.visible = false;
      this.drawerTemplate.modalTimer = setTimeout(()=>{
        this.drawerTemplate.modalHide = true;
      }, this.drawerTemplate.duration*1000);
    },
    editHighlight(data) {
      // 编辑高亮
      if(data.id === this.ruleForm['contentobject-id']) return 'is_eidt_highlight'
      return ''
    },
    currentRoute(data) {
      // 当前路由
      // 内容页、主题页
      if(this.$route.params.contentId === data.id) {
        return 'is_current_route'
      }
      // 首页
      if(this.$route.params.id && !this.$route.params.contentId && this.$route.params.id === data.id) {
        return 'is_current_route'
      }
      return ''
    },
    getPopDetail(_type,isTop) {
      let pageType = _type;
      if(_type === 'page') pageType = "content";
      if(isTop == 1) pageType = "index";
      let schemaObj = {
        'index_styles': 'course-style',
        'menu_styles': 'contentobject-style',
        'content_styles': 'contentobject-style',
        'content_firstpage': 'course-style',
        'menu_firstpage': 'course-style',
        'index_condition': 'course-completion-conditions',
        'menu_condition': 'contentobject-completion-conditions',
        'content_condition': 'contentobject-completion-conditions',
        'menu_bookmarker': 'contentobject-bookmarking',
        'content_bookmarker': 'contentobject-bookmarking',
      };
      let schemaName = schemaObj[ pageType +'_'+ this.drawerSet.type ];
      if(!['styles','condition','firstpage','bookmarker'].includes(this.drawerSet.type)) return [];
      let detail = this.schemaJson[ schemaName ];
      
      detail.page_key = schemaName;
      return detail;
    },
    getCkId() {
      let pageType = this.pageType;
      let ids = {
        'index': 'course_id',
        'content': 'contentobject_id',
        'menu': 'contentobject_id',
      };
      return ids[pageType];
    },
    tapTreeNode(nodeP,node, self) {
      // console.log(nodeP,node, self,)
      // console.log("tapTreeNode",nodeP.isFolder == 'folder' && nodeP.isTop == 1,nodeP.isFolder == 'folder' , nodeP.isTop)
      if(this.$route.params.id == nodeP.id && !this.$route.params.contentId 
      || this.$route.params.id == nodeP.course_id && this.$route.params.contentId == nodeP.id) {
        return;
      }
      
      // 切换路由，收起左侧弹窗
      if(this.drawerSet.visible) {
        this.hideLeftModal();
      }
      // 跳转
      if(nodeP._parentId == 0) { //首页
        this.$router.push({path:`/course/edit/${nodeP.id}`, query:{ pageType: 'index'}})
      }else {
        this.$router.push({path:`/course/edit/${nodeP.course_id}/${nodeP.id}`, query:{ pageType: nodeP._type == "menu" ? 'menu' : 'content'}})
      }
    },
    clickTab(type) {
      // 控制点击时不显示弹出窗口
      this.drawerSet.clickTime = true;
      setTimeout(() => {
        this.drawerSet.clickTime = false;
      },50);
      this.activeTab = type == this.activeTab ? 'none' : type;

      if(this.activeTab === 'none') { 
        this.toggleSidebar('pickup');
      }else {
        this.toggleSidebar('expand');
      }
      if(type === 'courseTheme' && this.templateData.total == -2) {
        this.getCurrentTheme();
      }
      // 当前路由内点击
      if( (type).toLowerCase().indexOf(this.routeType) > -1 ) return;
      // 跳转
      if(this.routeType == 'theme') {
        this.navto(type);
      }
      
    },
    navto(type) {
      switch(type) {
        case "courseEdit":
          if(this.lastHistory && this.lastHistory.url) {
            this.$router.push(this.lastHistory.url);
            this.lastHistory = null;
          }else {
            this.$router.push("/course/edit/"+this.$route.params.id+ "?pageType=index");
          }
          // this.$navBlank({
          //   path: "/course/edit/" + this.$route.params.id,
          //   query: {
          //     pageType: "index"
          //   },
          //   pageId: this.$route.params.id
          // });
          break;
        case "courseTheme":
          this.$router.push("/course/theme/"+this.$route.params.id);
          break;
        case "courseCreate":
        default:
          this.$router.push("/course/create"+ "?pageType=index");
          break;
      }
    },
    handleAvatarSuccess() {},
    beforeAvatarUpload() {},
    handleClick() {},
    toolshover(type, id) {
      // console.log(type, id, this.$refs.tree.getNode(id));
      this.showTools = !!(type == 1);
      this.currentId = id;

      if (this.showTools) {
        this.$refs["tree"].setCurrentKey(id);
      } else {
        // 高亮取消
        this.$refs["tree"].setCurrentKey(0);
      }
    },
    commandItem(type, item) {
      return {
        type: type,
        item: item,
      };
    },
    toggleSidebar(type) {
      this.isExpand = type === "expand";
    },
    tabClick(tab, event) {
      // console.log(tab, event);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    treeNodeTool({ type, item, node }) {
      if(["styles","firstpage","condition","bookmarker"].includes(type)) {
        let itemRes = JSON.parse(JSON.stringify(item));
        let properties_list = itemRes.properties_list;
        delete itemRes.properties_list;
        this.drawerSet.type = type;
        this.popDetail = this.getPopDetail(item._type,item.is_course);
        this.ruleForm = {};
        this.ruleForm = Object.assign(itemRes, properties_list);
        
      }
      if(this.drawerSet.visible) {
        this.hideLeftModal();
      }
      let _parentId = !item._parentId ? item.course_id : item.id;


      switch (type) {
        case "addcontentpage":
          this.CreateContentObject({course_id:item.course_id,_parentId: _parentId,_type:'page'}).then(res => {
            if(res.errorcode == 0) {
              this.$message("内容页创建成功！");
              if(_parentId == this.$route.params.contentId && this.pageType !== 'index' || this.pageType === 'index' && _parentId == item.course_id) {
                // 刷新当前页面主题
                this.getSonContentList(item.course_id, item.id);
              }
            }
          }).catch();
          break;
        case "addthemepage":
          this.CreateContentObject({course_id:item.course_id,_parentId: _parentId,_type:'menu'}).then(res => {
            if(res.errorcode == 0) {
              this.$message("主题页创建成功！");
              if(_parentId == this.$route.params.contentId && this.pageType !== 'index' || this.pageType === 'index' && _parentId == item.course_id) {
                // 刷新当前页面主题
                this.getSonContentList(item.course_id, item.id);
              }
            }
          }).catch();
          break;
        case "styles":
          if(this.drawerSet.modalTimer) clearTimeout(this.drawerSet.modalTimer);
          // this.drawerSet.title = this.popDetail.title + (this.popDetail.page_key === 'course-style' ? '（首页）' : '');
          this.setDrawSetTitle(this.popDetail.title, item.title, this.popDetail.page_key.indexOf('course-') > -1);
          this.drawerSet.visible = true;
          this.drawerSet.modalHide = false;
          setTimeout(()=>{
            this.showTools = true;
            this.currentId = item.id;
            this.$refs["tree"].setCurrentKey(item.id); //保持高亮
          },200);
         
          break;
        case "firstpage":
          if(this.drawerSet.modalTimer) clearTimeout(this.drawerSet.modalTimer);
          this.drawerSet.title = "设置首页";
          this.drawerSet.htmlTitle = `${item.label || item.title}`;
          this.drawerSet.visible = true;
          this.drawerSet.modalHide = false;
          break;
        case "condition":
          if(this.drawerSet.modalTimer) clearTimeout(this.drawerSet.modalTimer);
          this.setDrawSetTitle(this.popDetail.title, item.title, this.popDetail.page_key.indexOf('course-') > -1);
          this.drawerSet.visible = true;
          this.drawerSet.modalHide = false;
          break;
        case "bookmarker":
          // 书签
          if(this.drawerSet.modalTimer) clearTimeout(this.drawerSet.modalTimer);
          this.setDrawSetTitle(this.popDetail.title, item.title, this.popDetail.page_key.indexOf('course-') > -1);
          this.drawerSet.visible = true;
          this.drawerSet.modalHide = false;
          break;
        case "copy":
          this.CopyContentObjectOne({course_id:this.$route.params.id, contentobject_id:item.contentobject_id}).then(res => {
            if(res.errorcode == 0) {
              // 刷新目录树
              this.GetTree(this.$route.params.id);
              // 当前页是首页/主题页时，刷新
              if(this.pageType === "index" ||  parentId == this.$route.params.contentId && this.pageType == 'menu') {
                this.getSonContentList(item.course_id, item.id);
              }
            }
          }).catch(err => {

          });
          break;
        case "rename":
          // 重命名
          this.delDialogDetail.inputVal = item.properties_list && item.properties_list['contentobject-title'] || item.title;
          this.delDialogDetail.title = "重命名";
          this.delDialogDetail.type = "rename";
          this.delDialogDetail.item = item;
          this.delDialogDetail.visible = true;
          break;
        case "del":
          this.delDialogDetail.title = "删除";
          this.delDialogDetail.type = "del";
          this.delDialogDetail.item = item;
          this.delDialogDetail.visible = true;
          break;
      }
    },
    setDrawSetTitle(typeTitle, pageTitle, isHomePage) { //设置弹出窗口 title
      this.drawerSet.title = typeTitle;
      this.drawerSet.htmlTitle = `${isHomePage ? '首页' : pageTitle}`;
    },
    formChangeBlur(value,propName,leftForm) {
      let obj = {};
      obj[propName] = value;
      // console.log(value,propName,leftForm)
      this.$set(this.ruleForm,propName,value);
      this.$forceUpdate(); //强制刷新，组件嵌套
      this.saveDetail(obj,leftForm);
    },
    saveDetail(updateObj,leftForm={refreshOther:true}, successFn) {
      let params = {
        "page_key":this.popDetail.page_key,
        "course_id":leftForm.course_id,
        "properties_data": updateObj,
        "isLeftUpdate":true,
        "refreshOther": leftForm.refreshOther
      };
      if(this.popDetail.page_key && this.popDetail.page_key.indexOf('contentobject') > -1) {
        params["contentobject_id"] = leftForm['contentobject-id'];
      }
      //保存详情
      this.SaveCourse(params).then(res=>{
        if(res.errorcode == 0) {
          successFn && typeof successFn === "function"  && successFn(res);
          if(updateObj.hasOwnProperty("course-customStyle")) {
            // 更改了自定义css
            this.SET_CUSTOM_CSS({ data: res.data["course-customStyle"], course_id: leftForm.course_id });
          }
          if(this.popDetail.page_key.indexOf('course-style') > -1) {
            // 修改了左侧课程 - 样式设置
            this.ruleForm = Object.assign(this.ruleForm, res.data)
            // for(let key  in updateObj) {
            //   this.ruleForm[key] = res.data[key]
            // }
          }
          if(this.popDetail.page_key.indexOf('contentobject') > -1) {
            // 修改到内容
            // 修改的是否当前页
            let isCurrent = leftForm.contentobject_id && leftForm.contentobject_id == this.$route.params.contentId;
            isCurrent && this.GetContentObjectOne({
              course_id: leftForm.course_id,
              contentobject_id: leftForm.contentobject_id,
              isMenu: leftForm._type == 'menu',
              isCurrent: isCurrent
            }).then(resp => {
              this.ruleForm = Object.assign(this.ruleForm, resp.data.contentobject.properties_list);
              // for(let key  in updateObj) {
              //   this.ruleForm[key] = resp.data.contentobject.properties_list[key]
              //   this.$set(this.ruleForm,key,resp.data.contentobject.properties_list[key]);
              // }
              // this.$forceUpdate();
            }).catch();
          }
        }
      })
    },
    changeHomeSet(val,{key_name, properties_name}) {
      // 设置为首页
      let { contentobject_id, course_id } = this.ruleForm;
      let status = this.ruleForm['contentobject-__start-properties-status'] ||  false;
      let _skipIfComplete = this.ruleForm['contentobject-__start-properties-_skipIfComplete'] || false;
      if(properties_name === 'status') {
        status = val;
      }else if(properties_name === '_skipIfComplete'){
        _skipIfComplete = val;
      }
      updateIndexSet({
        course_id: course_id, 
        contentobject_id: contentobject_id, 
        status: (status == true || status == 1) ? 1 : 0, 
        _skipIfComplete: (_skipIfComplete == true || _skipIfComplete == 1) ? 1 : 0
      }).then(res => {
        if(res.errorcode == 0) {
          this.ruleForm = Object.assign(this.ruleForm, res.data.contentobject);
          // 刷新目录树
          this.GetTree(this.$route.params.id);
          // 刷新首页设置
          this.GetHomeSetting(this.$route.params.id);
        }
      }).catch();
    },
    handleDragStart(node, ev) {
      // console.log('drag start', node);
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      // console.log('tree drag enter: ', dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      // console.log('tree drag leave: ', dropNode.label);
    },
    handleDragOver(draggingNode, dropNode, ev) {
      // console.log('tree drag over: ', dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      // console.log('tree drag end: ', dropNode && dropNode.label, dropType);
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      // console.log('tree drop: ', dropNode.label, dropType);
      //当前节点-内容页，必会有上下移动，有上/下节点，和父节点 
      // dropType == before 同级
      // dropType == after 同级
      let parentId = !dropNode.parent ? dropNode.data.course_id : (Array.isArray(dropNode.parent.data) ? dropNode.parent.data[0]._id : dropNode.parent.data._id);
      let params = {
        course_id: dropNode.data.course_id,
        _parentId: parentId,
        contentobject_id: draggingNode.data.contentobject_id,
        move_type: dropType == 'before' ? 'up' : 'down',
      };
      if(dropNode.data.contentobject_id != undefined) {
        params['below_contentobject_id'] = dropNode.data.contentobject_id;
      }
      this.MoveNode(params).then(res => {
        if(res.errorcode == 0) {
          // 当前移动节点，是当前内 子主题
          if(parentId == this.$route.params.id && this.pageType === 'index' 
          ||  parentId == this.$route.params.contentId && this.pageType == 'menu') {

            this.getSonContentList(dropNode.data.course_id, parentId);
          }
        }
      }).catch();
      
    },
    allowDrop(draggingNode, dropNode, type) {
      // 拖拽时判定目标节点能否被放置
      // console.log('allowDrop', type)
      // console.log('allowDrop',draggingNode, dropNode, type,dropNode.data._type,dropNode.data.title)
      // 目标节点不可以是 内容页 且 type == inner
      // 目标节点不可以是 首页 且 type != inner

      if(dropNode.data._type === "page" && type == "inner"  || dropNode.data._parentId == 0 && type != "inner") {
        return false;
      } else {
        return true;
      }
    },
    allowDrag(draggingNode) {
      // 判断节点能否被拖拽
      // console.log('draggingNode',draggingNode)
      // 父节点只有一个子节点时，不可以拖拽
      if(draggingNode.parent.childNodes.length === 1) return false;
      return true;
    },
    getSonContentList(course_id,contentObjectId) {
      // 获取子主题列表
      let contParams = {
        course_id: course_id,
      };
      if(this.pageType == 'menu') {
        contParams.contentObjectId = contentObjectId;
      }
      this.GetContentObjectSonList(contParams);
    },
    handleCloseDel(done) {
      done();
      this.delDialogDetail.visible = false;
      this.delDialogDetail.item = {};
    },
    closeDelDialog() {
      this.delDialogDetail.visible = false;
      this.delDialogDetail.item = {};
    },
    delPage() {
      // 删除页面
      let item = this.delDialogDetail.item;
      let currentParams = {course_id:item.course_id, contentobject_id:item.contentobject_id};
      // 删除的是否当前页
      let isDelCurrent = item.contentobject_id && item.contentobject_id == this.$route.params.contentId;
      if(isDelCurrent) {
        // 删除当前页不刷新目录树 -> 重定向首页
        currentParams.updateDetail = {tree:false};
      }
      this.DelContentObjectOne(currentParams).then(res=>{
        if(res.errorcode == 0) {
          this.closeDelDialog();
          if(item._type == "menu") {
            this.$message("主题页删除成功！");
          }else {
            this.$message("内容页删除成功！");
          }
          // 如果删除是当前页面,重定向到首页
          if(isDelCurrent) {
            this.$router.push(`/course/edit/${this.$route.params.id}?pageType=index`);
            // this.$navBlank({
            //   path: "/course/edit/" + this.$route.params.id,
            //   query: {
            //     pageType: "index"
            //   },
            //   pageId: this.$route.params.id
            // });
            return;
          }
          // 刷新当前页面主题
          if(this.pageType === "menu" || this.pageType === "index") {
            this.getSonContentList(item.course_id, item.id);
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
// 实现滚动
.pageTree-inner {
  height: calc(100% - 52px);
  flex: 1 1 0%;
  overflow: hidden auto;
  // 隐藏滚动条
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background: 0 0;
  }
  &::-webkit-scrollbar-thumb {
    background: #dadadb;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0b2b3;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #b0b2b3;
  }
}
/deep/.el-tree {
    // height: calc(100% - 52px);
    height: 100%;
  & > .el-tree-node {
    // height: 100%;
    // flex: 1 1 0%;
    // overflow: hidden auto;
    // 隐藏滚动条
    // &::-webkit-scrollbar {
    //   width: 0;
    //   height: 0;
    // }
    // &::-webkit-scrollbar-button {
    //   width: 0;
    //   height: 0;
    // }
    // &::-webkit-scrollbar-track {
    //   background: 0 0;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background: #dadadb;
    //   -webkit-transition: 0.3s;
    //   transition: 0.3s;
    // }
    // &::-webkit-scrollbar-thumb:hover {
    //   background-color: #b0b2b3;
    // }
    // &::-webkit-scrollbar-thumb:active {
    //   background-color: #b0b2b3;
    // }
  }
}
/deep/.el-tree-node__expand-icon {
  // 隐藏原有icon
  padding: 10px;
  transform: rotate(-90deg);
  // display: none;
}

// 搜索框
/deep/.el-input--prefix .el-input__inner {
  padding-left: 36px;
}
/deep/.el-input .el-input__prefix {
  left: 10px;
}
/deep/.el-input .el-input__inner::placeholder { font-size: 12px; color: #BFBFBF; }
.sidebar-search {
  box-sizing: border-box;
  margin: 8px 6px;
  width: calc(100% - 12px);
  height: 34px;
  line-height: 34px;
  background-color: #F5F5F5;
  .input__icon {
    line-height: 34px;
  }
}

.coursepanel-sidebar {
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  z-index: 10;
  // width: 302px;
  height: 100%;
  background: #fafafa;
}

@boxWidth:72px;
.sidebar-tabs {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  border-right: 1px solid #E4E4E4;
  // padding-top: 30px;
  width: @boxWidth;
  height: 100%;
  text-align: center;
  background-color: #FFFFFF;
  .sidebar-tabs-item {
    position: relative;
    box-sizing: border-box;
    border: 1px solid transparent;
    // margin-bottom: 8px;
    width: @boxWidth;
    height: @boxWidth;
    &:hover {
      color: #E74362;
      background-color: #FCE6EA;
      
      .icon-page::before {
        color: #E74362;
      }
      .icon-theme {
        .path2::before,
        .path3::before {
          color: #E74362;
        }
      }
    }
    &.active {
      color: #E74362;
      background-color: #FCE6EA;
    }
    // &:hover {
    //   color: #E74362;
    //   .sidebar-tabs-tips {
    //     display: block;
    //   }
    // }
    // &.active {
    //   color: #E74362;
    //   // background-color: #F8EFF1;
    //   &::before {
    //     position: absolute;
    //     left: -1px;
    //     top: 0;
    //     bottom: 0;
    //     content: "";
    //     width: 4px;
    //     background-color: #E74362;
    //     border-radius: 0 100px 100px 0;
    //   }
    // }
    &:first-child {
      // border-bottom-color: #E4E4E4;
      &.notactive {
        border-bottom-color: transparent;
      }
    }
    .sidebar-tabs-svg {
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      
      &.type-page {
        background-image: url(~@/assets/svg/leftsidebar/edit-page-default.svg);
      }
      &.type-theme {
        background-image: url(~@/assets/svg/leftsidebar/edit-theme-default.svg);
       
      }
      &.type-setting {
        background-image: url(~@/assets/svg/leftsidebar/edit-globalsetting-default.svg);
       
      }
    }
    .sidebar-tabs-tips {
      display: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(34px, -50%);
      border-radius: 4px;
      font-size: 12px;
      color: #FFFFFF;
      width: 40px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      background-color: #333333;
    }
  }
  .toUpgrade {
    position: absolute;
    left: 50%;
    bottom: 29px;
    transform: translateX(-50%);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-image: url('~@/assets/svg/leftsidebar/edit-membershipUpgrade-default.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px auto;
    background-color: #F8EFF1;
    cursor: pointer;
    &:hover {
      transition: all 0.2s;
      background-color: #E74362;
      background-image: url('~@/assets/svg/leftsidebar/edit-membershipUpgrade-selected.svg');
    }
  }
}
.sidebar-content {
  flex-shrink: 0;
  box-sizing: border-box;
  z-index: 1;
  max-width: 230px;
  height: 100%;
  box-shadow: 1px 0 8px 0 rgba(0,0,0,0.12);
  transition: all 0.2s ease-in-out 0s;
}
.sidebar-fold {
  z-index: 9;
  
  &.shadow {
    box-shadow: 1px 0px 8px -1px rgba(0,0,0,0.12);
  }
}

.pickup_wrap {
  position: absolute;
  top: 50%; 
  right: 0;
  z-index: 999;
  transform: translate(100%, -50%);
  width: 20px;
}
// 收起按钮
.pickup_btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  border-radius: 0 4px 4px 0;
  width: 20px;
  height: 50px;
  box-shadow: 1px 2px 8px 0px rgba(0,0,0,0.12);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  &:hover {
    background-color: #F8F8F8;
  }
  .img {
    width: 20px;
    height: 50px;
  }
}

.pageTree {
  height: 100%;
  background-color:#FFFFFF;
}
.active_border {
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    width: 33px;
    height: 1px;
    background-color: #e74362;
  }
}
// 文件树

//树节点高亮
@nodeActivedBg:  #F8EFF1;
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: @nodeActivedBg;
  .custom-tree-dropdown {
    opacity: 1;
  }
}

/deep/.is-focusable {
  & > .el-tree-node__content {
    background-color: #fff;
  }
}

/deep/.el-tree-node__content {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  // padding-left: 0!important;
  // 当前路由
  &.is_current_route,
  &.is_eidt_highlight {
    background-color: @nodeActivedBg;
  }
  &:hover {
    background-color: @nodeActivedBg;
    // color:#E74362;
    .custom-tree-label-html {
      // color:#E74362 !important;
    }
    .custom-tree-dropdown {
      opacity: 1;
    }
  }

  .custom-tree-node {
    position: relative;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    // 悬浮时
    &:hover {
      .custom-tree-dropdown {
        opacity: 1;
      }
    }
    // 当前路由
    // &.is_current_route {
    //   background-color: @nodeActivedBg;
    // }
    // 编辑弹窗
    // &.is_eidt_highlight {
    //   background-color: @nodeActivedBg;
    // }
  }
  .custom-tree-node-inner {
    position: relative;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   right: 15px;
    //   width: calc(100% - 15px);
    //   height: 1px;
    //   background-color: @nodeActivedBg;
    // }
    .custom-tree-dropdown {
      opacity: 0;
      position: absolute;
      right: 8px;
    }
  }
}

// 树结构标题
.custom-tree-label-html {
  height: 20px;
  line-height: 20px;
  font-size: 14px!important;
  color: #262626!important;
  width: calc(100% - var(--tree-label-width));
  // width: calc(100% - 64px); //page: -64px , menu: -48px
  /deep/p,
  /deep/span {
    font-size: 14px!important;
    color: #262626!important;
  }
  // &.isFold {
  //   color: rgba(51, 51, 51, 0.6);
  // }
}
// 被展开的图标
.icon-tree-folder4 {
  transition: transform 0.3s;
}
/deep/.expanded.el-tree-node__expand-icon + .custom-tree-node > .custom-tree-node-inner {
  & > .icon-tree-folder4 {
    transform: rotate(90deg);
  }
}
/deep/.el-tree-node__expand-icon {
  font-size: 12px;
  &::before {
    content: "\ea08";
    color: #222222;
    font-family: 'icomoon';
  }
  &.expanded {
    transform: rotate(0) !important;
  }
}
/deep/.icon-tree-folder4 {
  transition: transform 0.3s;
}
/deep/.expanded.icon-tree-folder4 {
  transform: rotate(90deg);
}

.filter-tree {
  background-color: inherit;
}

// 自定义 左边侧边栏弹窗
.left-modal {
  box-sizing: border-box;
  position: absolute;
  z-index: 8;
  left: 100%;
  transform: translateX(-100%);
  transition: all 0.5s;
  bottom: 0;
  // width: 336px;
  // width: 30rem;
  width: 28vw;
  max-width: 336px;
  height: 100%;
  background:  #F5F5F5;
  // opacity: 0;

  .left-modal-main {
    height: calc(100%);
  }
  /deep/.el-tabs__content {
    box-sizing: border-box;
    height: calc(100% - 40px); //40px 是顶部导航栏
  }

  &.visible {
    // opacity: 1;
    box-shadow: 10px 2px 20px 0 rgba(0, 0, 0, 0.15);
    transform: translateX(0);
    // & + .left-modal-mask {
    //   display: block;
    // }
  }
}
// 自定义 左侧弹窗 遮罩层 - 要在左侧完全收回时，才销毁
.left-modal-mask {
  // display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.15);
  &.left-mask-hide {
    display: none;
  }
}
.left-modal-form {
  height: calc(100% - 48px);
}
// 左侧二层弹窗 无tab
.left-modal-single {
  box-sizing: border-box;
  padding: 16px;
}

// 左侧弹出-tabs
/deep/.el-drawer__body {
  overflow: hidden;
  height: calc(100% - 97px);
}
/deep/.el-tabs,
/deep/.el-tab-pane {
  height: 100%;
}
/deep/.el-tabs__header {
  margin-bottom: 0;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none;
}

// 主题部分
.sidebar-theme-container {
  box-sizing: border-box;
  padding: 16px 0 3px 0;
  box-shadow: 1px 2px 8px 0 rgba(0,0,0,0.12);
}
.sidebar-theme-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  margin-inline: auto;
  border-radius: 4px;
  width: calc(100% - 32px);
  height: 40px;
  background-color: #EDEDED;
  .theme-tab-item {
    border-radius: 4px;
    width: calc(50% - 8px);
    height: 32px;
    line-height: 32px;
    text-align: center;
    &.active {
      box-shadow: 0 0 6px 0 rgba(0,0,0,0.12);
      background: #FFFFFF;
    }
  }
}
.sidebar-theme-tabs-outer {
  position: relative;
  z-index: 9;
  background-color: #FFFFFF;
}
.sidebar-theme-list {
  position: relative;
  z-index: 9;
  height: calc(100% - 56px);
  background-color: #FFFFFF;
  .theme-item {
    padding: 12px 16px;
    &.editing {
      // background-color: #EDEDED;
      .theme-item-inner:hover {
        // border-color: #D4D4D4;
      }
    }
    
  }
  .theme-item-inner {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    height: 18.3rem;
    max-height: 183px;
    &.active {

    }
    &:hover {
      // border-color: #E74263;
    }

  }
  @themeImage:12.9rem;
  .theme-item-image {
    overflow: hidden;
    border-radius: 4px;
    // margin-top: 14px;
    margin-top: 0.8rem;
    width: 100%;
    // height: calc(100% - 2.4rem - 20px);
    flex: 1;
    .img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .theme-item-mask {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(180deg, rgba(102,102,102,0.60) 0%, rgba(0,0,0,0.90) 100%);
    transition: opacity 0.6s;
    &:hover {
      opacity: 1;
    }
  }
  .icon-wrapper {
    .edit_text {
      margin-top: 5px;
    }
  }
  .split_line {
    margin: 0 17px;
    border-radius: 0.5px;
    height: 10px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
// 主题复选框
.theme-checkbox-wrapper {
  .theme-checkbox-mask {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background-color: rgba(0, 0, 0, .15);
    cursor: pointer;
  }
  .theme-item-edit {
    position: relative;
    z-index: 2;
    display: none;
    // opacity: 0;
    &.dropdown-show {
      display: block;
    }
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    // overflow: hidden;
    flex: 1;
    height: 20px;

    /deep/.el-checkbox__label {
      width: 80%;
      span {
        display: inline-block;
        width: 8em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
@media screen and (max-width: 1700px){
  .theme-checkbox-wrapper .checkbox-wrapper /deep/.el-checkbox__label span {
    width: 8em;
  }
}
@media screen and (max-width: 1600px){
  .theme-checkbox-wrapper .checkbox-wrapper /deep/.el-checkbox__label span {
    width: 7em;
  }
}
@media screen and (max-width: 1200px){
  .theme-checkbox-wrapper .checkbox-wrapper /deep/.el-checkbox__label span {
    width: 4em;
  }
}

.theme-mask-edit {
  .icon-gray-more:before {
    color: #a7a7a7;
  }
}

.theme-item-inner:hover {
  .dropdown-show {
    opacity: 1;
  }
}

.my_template_outer {
  box-sizing: border-box;
  padding-right: 16px;
  .my_template_text {
    padding-right: 20px;
    cursor: pointer;
    color: #E74362;
    &:hover {
      color: #B5102F;
      background-image: url(~@/assets/svg/more_right_arrow.svg);
    }
    background-image: url(~@/assets/svg/more_right_arrow_default.svg);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: right center;
  }
}
.tag_vip {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: max-content;
  color: #FE840F;
  line-height: 1;
  .tag_vip_icon {
    margin-right: 6px;
    width: 12px;
    height: 12px;
  }
}
.icon-btn {
  border: 1px solid #FFFFFF;
  border-radius: 2px;
  width: 64px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  &:hover {
    background: rgba(255,255,255,0.20);
  }
}
.template_modal {
  width: 22rem;
  max-width: 230px;
  padding: 16px 0 0 0;
  background-color: #FFFFFF;
  .theme-checkbox-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &.sidebar-template-list {
    border-left: 1px solid #f2f2f2;
    .theme-item {
      padding: 16px;
      &.editing {
        // background-color: #EDEDED;
        .theme-item-inner:hover {
          // border-color: #D4D4D4;
        }
      }
      
    }
    .theme-item-inner {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      border: 1px solid #D4D4D4;
      border-radius: 4px;
      // padding: 10px;
      padding: 1rem;
      // width: 199px;
      width: 100%;
      // height: 183px;
      height: 18.3rem;
      max-height: 183px;
      &.active {

      }
      &:hover {
        // border-color: #E74263;
      }

    }
    @themeImage:12.9rem;
    .theme-item-image {
      overflow: hidden;
      border-radius: 4px;
      // margin-top: 14px;
      margin-top: 0.8rem;
      width: 100%;
      // height: calc(100% - 2.4rem - 20px);
      flex: 1;
      .img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .theme-item-mask {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(180deg, rgba(102,102,102,0.60) 0%, rgba(0,0,0,0.90) 100%);
      transition: opacity 0.6s;
      &:hover {
        opacity: 1;
      }
    }
    .split_line {
      margin: 0 17px;
      border-radius: 0.5px;
      height: 10px;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

.icon-upgrade-remind:before {
  color: #faad14;
}
/deep/.theme-dialog {
  .el-dialog__header { display: none; }
  .el-dialog__body {
    padding: 32px 32px 24px;
  }
}
.changeTheme-title {
  font-weight: bolder;
}
.changeTheme-content {
  color: #FF003C;
}
</style>
